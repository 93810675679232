import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAlgoActivityData } from '../http'
import { AlgoActivity } from '../interfaces/AlgoActivity'
import { stat } from 'fs'

interface AlgoActivityState {
    algoActivityData: AlgoActivity | null,
    loading: boolean
    errors: any
}

const initialState: AlgoActivityState = {
    algoActivityData: null,
    loading: false,
    errors: null
}

export const getAlgoActivity = createAsyncThunk(
    'algoActivity/getAlgoActivity',
    async ({ token}: { token: any}, thunkAPI) => {
        try {
            const response = await getAlgoActivityData(token, 'transaction-master/activity')
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const algoActivitySlice = createSlice({
    name: 'algoActivity',
    initialState,
    reducers: {
        setAlgoActivity: (state, action: PayloadAction<AlgoActivity>) => {
            state.algoActivityData = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAlgoActivity.pending, (state, action) => {
            state.loading = true
            state.algoActivityData = null
        })
        builder.addCase(getAlgoActivity.fulfilled, (state, action) => {
            state.loading = false
            state.algoActivityData = action.payload
            state.errors = null
        })
        builder.addCase(getAlgoActivity.rejected, (state, action) => {
            state.loading = false
            state.errors = action.payload
            state.algoActivityData = null
        })
    }
})

export default algoActivitySlice.reducer
export const { setAlgoActivity } = algoActivitySlice.actions
