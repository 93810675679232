import React, { useState, useEffect, useCallback, useMemo, Fragment } from "react";
import { 
    Dialog, DialogContent, IconButton, Box, 
    FormControl, AppBar, DialogActions, Button, 
    Container, Toolbar, Avatar, Typography, Paper, 
    Table, TableHead, TableBody, TableRow, TableCell, 
    TableContainer, Divider, TextField, Checkbox, 
    CircularProgress, Autocomplete, Snackbar, Alert, 
    TableSortLabel, Chip, 
    Tooltip} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { styled } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from "../../../Store";
import { useAuth } from "../auth";
import { getSyncAlgoByUserID } from "../reducers/syncAlgoSlice";
import { syncAlgoByUser } from '../http';
import { visuallyHidden } from '@mui/utils';
import CachedSharpIcon from '@mui/icons-material/CachedSharp';
import { SymbolLiveChartDialog } from "./SymbolLiveChartDialog";
import { CustomChip } from "../../../utils/helper/commonComponents";

const styles = {
    customTableHead: {
        backgroundColor: 'lightblue',
    }
}

const headCells: any[] = [
    {
      id: 'strategy', 
      align: 'left',
      disablePadding: false,    
      label: 'Strategy',
      width: 250
    },
    {
      id: 'tradingSymbol',   
      align: 'left',
      disablePadding: false,  
      label: 'Symbol',
      width: 150
    },
    {
      id: 'side',   
      align: 'center',
      disablePadding: false, 
      label: 'Signal',
      width: 10
    }, 
    {
      id: 'lotSize', 
      align: 'right',
      disablePadding: false,   
      label: 'Lot/Lot Size',
      width: 10
    },
    {
        id: 'qty', 
        align: 'right',
        disablePadding: false,   
        label: 'Qty',
        width: 15
      },
    {
        id: 'status',   
        align: 'center',
        disablePadding: false, 
        label: 'Status',
        width: 10
    }
]

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: '#f0f0f0',#E5E4E2, #f7f6f9, #FAF9F6
        backgroundColor: '#fcf7f7',
        innerHeight: '50',
        margin: '5 5 0 0'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledNameCell = styled(TableCell)({
    width: '60%',
})

const StyledSmallCell = styled(TableCell)({
    width: '10%',
})  

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    // Special handling for qty column
    if (orderBy === 'qty' || orderBy === 'lotSize') {
        // Extract the qty value (last number after the last slash)
        const aQty = Number(String(a[orderBy]).split('/').pop());
        const bQty = Number(String(b[orderBy]).split('/').pop());
        
        if (bQty < aQty) return -1;
        if (bQty > aQty) return 1;
        return 0;
    }


    // Handle nested properties for strategy and tradingSymbol
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    // Handle string comparison case-insensitively
    if (typeof aValue === 'string' && typeof bValue === 'string') {
        return bValue.toLowerCase().localeCompare(aValue.toLowerCase());
    }

    // Handle number comparison
    if (bValue < aValue) return -1;
    if (bValue > aValue) return 1;
    return 0;
}
  
type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort<T>(array: any[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export type IDataProps = Readonly<{
    open: boolean,    
    dialogClose: () => void,
}>;

interface SyncAlgoOption {
    id: string
    algoName: string
}

const ALL_OPTION: SyncAlgoOption = {
    id: 'all',
    algoName: 'All'
};

export function StrategySyncDialog({open, dialogClose}: IDataProps) {
    // State management
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [syncAlgoState, setSyncAlgoState] = useState<any>([]);
    const [selectedAlgo, setSelectedAlgo] = useState<SyncAlgoOption | null>(null);
    const [syncAlgoFilterState, setSyncAlgoFilterState] = useState<any>([]);   
    const [selectedRows, setSelectedRows] = useState<any[]>([]); 
    const [selectedRowsAlgoIds, setSelectedRowsAlgoIds] = useState<string[]>([]);
    const [isSyncAlgoLoading, setIsSyncAlgoLoading] = useState<boolean>(false);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof any>(headCells[0]?.id);
    const [syncStatus, setSyncStatus] = useState<{[key: string]: string}>({});
    const [lastFetchTime, setLastFetchTime] = useState<string | null>(null);
    const [isOpenSymbolChartDialog, setIsOpenSymbolChartDialog] = useState<boolean>(false);
    const [selectedSymbol, setSelectedSymbol] = useState<any>('');

    // State management for snacbar alert 
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState<string>('success');
    const [alertMessage, setAlertMessage] = useState('');

    const dispatch = useAppDispatch();
    const { auth, currentUser } = useAuth();
    const {syncAlgoData, loading} = useAppSelector((state) => state.syncAlgo);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof any,
        ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    
    const createSortHandler =
        (property: keyof any) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };   
    
    // Encapsulated logic to fetch sync algo data
    const fetchSyncAlgoData = () => {
        if (auth?.token && currentUser?.[0]?.userId) {
            dispatch(getSyncAlgoByUserID({
                token: auth.token,
                userId: currentUser[0].userId
            }));

            // Update last fetch time
            setLastFetchTime(new Date().toLocaleTimeString());
        }
    };

    // Fetch data on mount
    useEffect(() => {
        fetchSyncAlgoData();
    }, [dispatch, auth?.token, currentUser])

    // Refresh button handler
    const handleRefresh = () => {
        fetchSyncAlgoData();
    };

    // Update state when data changes
    useEffect(() => {
        if (syncAlgoData?.modal?.data) {
          setSyncAlgoState(syncAlgoData.modal.data);
          handleAlgoChange(null, ALL_OPTION);
          initializeFilteredState(syncAlgoData.modal.data);          
        }
    }, [syncAlgoData])

    // Initialize filtered state
    const initializeFilteredState = useCallback((data: any[]) => {
        const allSignals = data?.flatMap(algo => 
            algo.algoSignalDetails.map(signal => ({
                ...signal,
                algoId: algo.algoId,
                strategy: algo.generatedID 
            }))
        );
        setSyncAlgoFilterState(allSignals);
        setSelectedRows(allSignals?.map(item => item.id) || []);
        setSelectedRowsAlgoIds(allSignals?.map(item => item.algoId) || []);

        // Initialize status for all items
        const initialStatus = allSignals?.reduce((acc, item) => ({
            ...acc,
            [item.id]: 'Not Sync'
        }), {});
        setSyncStatus(initialStatus);
    }, []);

    // Handlers
    const handleAlgoChange = useCallback((event: any, newValue: SyncAlgoOption | null) => {
        setSelectedAlgo(newValue);
        if (!newValue) {
            setSyncAlgoFilterState([]);
            setSelectedRows([]);
            setSelectedRowsAlgoIds([]);
            return;
        }

        const signals = newValue.id === 'all' 
            ? syncAlgoState?.flatMap(algo => algo.algoSignalDetails.map(signal => ({
                ...signal,
                algoId: algo.algoId,
                strategy: algo.generatedID
                })))
            : syncAlgoState?.find(item => item.algoId === newValue.id)?.algoSignalDetails
                .map(signal => ({
                    ...signal,
                    algoId: newValue.id,
                    strategy: syncAlgoState.find(item => item.algoId === newValue.id)?.generatedID
                })) || [];

        setSyncAlgoFilterState(signals);

         // Only select rows that haven't been synced yet
        const unsyncedSignals = signals.filter(signal => syncStatus[signal.id] !== 'Send For Synced');
        setSelectedRows(unsyncedSignals.map(item => item.id));
        setSelectedRowsAlgoIds(unsyncedSignals.map(item => item.algoId));
    }, [syncAlgoState, syncStatus]);

    // Memoized values
    const syncAlgoOptions = useMemo(() => [
        ALL_OPTION,
        ...syncAlgoState?.map((item) => ({
        id: item?.algoId,
        algoName: item?.generatedID
        })) || []
    ], [syncAlgoState]);
    
    // Handle pagination
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Add handlers for checkbox selection
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = syncAlgoFilterState
                .filter(item => syncStatus[item.id] !== 'Send For Synced')
                .map(item => item.id);
            const newSelectedAlgoId = syncAlgoFilterState
                .filter(item => syncStatus[item.id] !== 'Send For Synced')
                .map(item => item.algoId);
            setSelectedRows(newSelected);
            setSelectedRowsAlgoIds(newSelectedAlgoId);
            return;
        }
        setSelectedRows([]);
        setSelectedRowsAlgoIds([]);        
    };

    const handleCheckboxClick = (item: any) => {
        if (syncStatus[item.id] === 'Send For Synced') return; // Prevent selection if synced

        const selectedIndex = selectedRows.indexOf(item?.id);
        let newSelected: string[] = [];
        let newSelectedAlgoId: string[] = [];

        if (selectedIndex === -1) {
            newSelected = [...selectedRows, item?.id];
            newSelectedAlgoId = [...selectedRowsAlgoIds, item?.algoId];
        } else {
            newSelected = selectedRows.filter((rowId) => rowId !== item?.id);
            newSelectedAlgoId = selectedRowsAlgoIds.filter((rowId) => rowId !== item?.algoId);
        }

        setSelectedRows(newSelected);
        setSelectedRowsAlgoIds(newSelectedAlgoId);
    };

    // Add handler for sync button
    const handleSyncSelected = async () => {
        try {
            setIsSyncAlgoLoading(true);
            // Get unique algoIds from selected rows
            const uniqueAlgoIds = Array.from(new Set(selectedRowsAlgoIds));
            
            // Get selected signals data from syncAlgoFilterState
            const selectedSignals = syncAlgoFilterState.filter(signal => 
                selectedRows?.includes(signal.id)
            );
    
            // Create the final sync data structure
            const syncData = uniqueAlgoIds.map(algoId => {
                // Find the original algo data
                const algoData = syncAlgoState.find(algo => algo.algoId === algoId);
                
                // Get all signals for this algo that were selected
                const algoSignals = selectedSignals.filter(signal => 
                    signal.algoId === algoId
                );
    
                if (algoSignals?.length > 0) {
                return {                
                        id: algoData?.id || null,
                        algoId: algoId,
                        version: algoData?.version || null,
                        generatedID: algoData?.generatedID || null,
                        signalGenerateDateTime: algoData?.signalGenerateDateTime || null,
                        signalSource: algoData?.signalSource || null,
                        algoSignalDetails: algoSignals.map(signal => ({
                            id: signal.id,
                            algoSignalMastersId: signal.algoSignalMastersId || null,
                            productType: signal.productType,
                            signalSymbolSource: signal.signalSymbolSource,
                            signalSymbolToken: signal.signalSymbolToken,
                            signalSymbol: signal.signalSymbol,
                            tradingSymbol: signal.tradingSymbol,
                            instrumentMaster: signal.instrumentMaster,
                            instrumentMasterAltenatives: signal.instrumentMasterAltenatives,
                            status: signal.status,
                            side: signal.side,
                            qty: signal.qty,
                            slNo: signal.slNo,
                            currencyCode: signal.currencyCode,
                            exchOrdId: signal.exchOrdId,
                            remainingQuantity: signal.remainingQuantity,
                            limitPrice: signal.limitPrice,
                            stopPrice: signal.stopPrice,
                            stopLoss: signal.stopLoss,
                            takeProfit: signal.takeProfit,
                            type: signal.type,
                            discloseQty: signal.discloseQty,
                            dqQtyRem: signal.dqQtyRem,
                            orderValidity: signal.orderValidity,
                            exchangeToken: signal.exchangeToken,
                            price: signal.price,
                            groupId: signal.groupId,
                            exchange: signal.exchange,
                            orderClass: signal.orderClass,
                            orderType: signal.orderType,
                            orderId: signal.orderId,
                            offlineOrder: signal.offlineOrder,
                            lotSize: signal.lotSize,
                            lot: signal.lot,
                            runningCommulativeQuantity: signal.runningCommulativeQuantity,
                            runningCommulativeProfitLoss: signal.runningCommulativeProfitLoss,
                            currentTransProfitLoss: signal.currentTransProfitLoss,
                            runnningCommLot: signal.runnningCommLot,
                            signalCategory: signal.signalCategory,
                            signalRequestedPrice: signal.signalRequestedPrice,
                            currentAvgPrice: signal.currentAvgPrice,
                            createdAt: signal.createdAt,
                            marketPosition: signal.marketPosition,
                            marketPositionSize: signal.marketPositionSize,
                            prevMarketPosition: signal.prevMarketPosition,
                            prevMarketPositionSize: signal.prevMarketPositionSize,
                            priority: signal.priority,
                            transactionDays: signal.transactionDays,
                            checkOutstandingBeforeTrade: signal.checkOutstandingBeforeTrade,
                            lastDayRank: signal.lastDayRank,
                            todayRank: signal.todayRank,
                            daysOnRank: signal.daysOnRank,
                            ltp: signal.ltp,
                            lastUpdatedLTPDateTime: signal.lastUpdatedLTPDateTime
                        }))
                    }
                };
                return null;
            }).filter(Boolean);;

            const response = await syncAlgoByUser(auth?.token ?? "", "strategy-sync/order", syncData);           

            if(response?.status === 200) {
                // Update status for synced items
                const newStatus = {...syncStatus};
                selectedRows.forEach(id => {
                    newStatus[id] = 'Send For Synced';
                });
                setSyncStatus(newStatus);

                // Clear selections after successful sync
                setSelectedRows([]);
                setSelectedRowsAlgoIds([]);

                setOpenAlert(true);
                setAlertMessage("Selected Strategy Detail sync successfully");
                setAlertType('success');
                // setTimeout(dialogClose, 2000);
            }
            else {
                throw new Error("Sync failed");
            }          
        } catch (error: any) {
            // Handle error appropriately
            setOpenAlert(true);
            setAlertMessage(error.response?.data?.apierror?.message ?? "Selected Strategy Detail not sync! Please try again");
            setAlertType('error');
            console.error('Sync failed:', error);
            // Show error notification
        } finally {
            setIsSyncAlgoLoading(false);
        }
    };

    const handleSymbolChartDialogOpen = (symbol: any) => {
        setSelectedSymbol(symbol);
        setIsOpenSymbolChartDialog(true);
    }

    const handleSymbolChartDialogClose = () => {
        setIsOpenSymbolChartDialog(false);
    }

    const renderTableRows = useCallback(() => {
        if(!syncAlgoFilterState?.length) {
            return (
                <StyledTableRow>
                    <TableCell colSpan={7}>
                        <span>Record not found.</span>
                    </TableCell>
                </StyledTableRow>
            )
        }

        const sortedData = stableSort(syncAlgoFilterState, getComparator(order, orderBy));
        return sortedData
            .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
            .map((_item) => (
                <StyledTableRow hover key={_item?.id}>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            // Show as checked if either selected OR already synced
                            checked={selectedRows?.includes(_item.id) || syncStatus[_item.id] === 'Send For Synced'}
                            onChange={() => handleCheckboxClick(_item)}
                            disabled={syncStatus[_item.id] === 'Send For Synced'} // Disable if synced
                        />
                    </TableCell>
                    <TableCell 
                        className="fs-9"
                        sx={{ width: 250 }}
                    >
                        {_item?.strategy}
                    </TableCell>
                    <TableCell 
                        className="fs-9" 
                        sx={{ 
                            cursor: 'pointer',
                            width: 150
                         }}
                    >
                        <div onClick={() => handleSymbolChartDialogOpen(_item?.tradingSymbol)}>
                            {_item?.tradingSymbol}{_item?.exchangeToken && `(${_item?.exchangeToken})`}
                        </div>                        
                    </TableCell>
                    <TableCell
                        align="center"
                        sx={{ width: 10 }}
                    >
                        <CustomChip 
                            label={_item?.side.toString().toUpperCase()} 
                            color={_item?.side.toString().toLowerCase() === "buy" ? "success" : "warning"}
                            width={50}
                            height={20}
                        />        
                    </TableCell>
                    <TableCell
                        align="right"
                        sx={{ width: 10 }}
                    >
                        {_item?.lot}/{_item?.lotSize}
                    </TableCell>
                    <TableCell
                        align="right"
                        sx={{ width: 15 }}
                    >
                        {_item?.qty}
                    </TableCell>
                    <TableCell
                        align="center"
                        sx={{ width: 10 }}
                    >
                        <Chip 
                            label={syncStatus[_item.id] || 'Not Sync'}
                            color={syncStatus[_item.id] === 'Send For Synced' ? 'success' : 'error'}
                            size="small"
                        />
                    </TableCell>
                </StyledTableRow>
            ));        
    }, [syncAlgoFilterState, page, rowsPerPage, selectedRows, order, orderBy, syncStatus]);

    return (
        <Fragment>
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth='md'
                keepMounted
            >
                <AppBar
                    position="static"
                    elevation={0}
                    // color='#212A47'
                    sx={{
                        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                        bgcolor: '#1C202E'
                    }}
                >                
                    <Container maxWidth='xl'>
                        <Toolbar variant="dense" disableGutters sx={{
                            minHeight: '10px'
                        }}>
                            <Avatar variant='rounded' src={toAbsoluteUrl('/media/logos/Til_logo.jpg')} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, width: 20, height: 20 }} />
                            <Typography
                                variant='h6'
                                component='div'
                                sx={{
                                    flexGrow: 1,
                                    textAlign: 'center'
                                }}
                            >
                                TIL Strategy Sync
                            </Typography>
                            <IconButton onClick={dialogClose}><CloseSharpIcon fontSize='medium' sx={{ color: 'white' }} /> </IconButton>
                        </Toolbar>
                    </Container>
                </AppBar>
                <DialogContent dividers>                
                    <Paper elevation={4} sx={{ width: '100%', overflow: 'hidden' }} className="mb-100">
                        <div className="d-flex col-12 mb-4 mt-4 ms-4 justify-content-between align-items-center">  
                            <div>                      
                                <FormControl size="small">
                                    <Autocomplete
                                        options={syncAlgoOptions}
                                        getOptionLabel={(option: SyncAlgoOption) => option.algoName} // Assuming `name` is the label field
                                        value={selectedAlgo}
                                        onChange={handleAlgoChange}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Algo"
                                                size="small"
                                            />
                                        )}
                                        sx={{ minWidth: 400 }}
                                    />
                                </FormControl>
                            </div>
                            <div className="d-flex align-items-center">
                                {lastFetchTime && 
                                    <div style={{ marginRight: '10px'}}>
                                        <strong>Last Updated:</strong> {lastFetchTime}
                                    </div>
                                }
                                <Tooltip title="Refresh">
                                    <IconButton
                                        className="me-6"
                                        onClick={handleRefresh}
                                    >
                                        <CachedSharpIcon fontSize='medium' />
                                    </IconButton>                                    
                                </Tooltip>
                            </div>
                        </div>
                        <Divider variant="middle" color='black' orientation='horizontal' /> 
                        <TableContainer className="ms-4" sx={{ maxHeight: 350 }}>
                            <Table stickyHeader size="small" aria-label="mui table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                indeterminate={selectedRows.length > 0 && selectedRows.length < syncAlgoFilterState.length}
                                                checked={syncAlgoFilterState.length > 0 && selectedRows.length === syncAlgoFilterState.length}
                                                onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                            {headCells?.map((headCell) => (
                                                <TableCell 
                                                    key={headCell?.id}
                                                    align={headCell?.align}
                                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                                    sortDirection={orderBy === headCell?.id ? order : "desc"} 
                                                    sx={{
                                                        width: headCell?.width || 'auto'
                                                    }}                  
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === headCell?.id}
                                                        direction={orderBy === headCell?.id ? order : "desc"}
                                                        onClick={createSortHandler(headCell?.id)}
                                                    >
                                                    <span className='fw-bold ms-2 fs-8'>
                                                        {headCell?.label}
                                                    </span>
                                                    {orderBy === headCell?.id ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))}                                         
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { loading ? (
                                        <StyledTableRow>
                                            <TableCell colSpan={7}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    minHeight="200px"
                                                >
                                                    <CircularProgress />
                                                </Box>
                                            </TableCell>
                                        </StyledTableRow>
                                    ) :
                                    (renderTableRows())
                                    }
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>  
                        <TablePagination
                            component="div"
                            count={syncAlgoFilterState.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[25, 50, 100]}
                        />        
                    </Paper>
                </DialogContent>
                <DialogActions>       
                    <div className="me-4">
                        <Button 
                            variant="outlined" 
                            color="primary"
                            disabled={selectedRows.length === 0 || isSyncAlgoLoading}
                            onClick={handleSyncSelected}
                            startIcon={isSyncAlgoLoading && <CircularProgress size={20} color="inherit" />}
                            sx={{
                                "&:hover": {
                                    variant: 'contained',
                                    backgroundColor: 'primary.main',
                                    color: 'white',
                                }
                            }}
                        >
                            {isSyncAlgoLoading ? 'Syncing...' : `Sync Selected (${selectedRows.length})`}
                            {/* Sync Selected ({selectedRows.length}) */}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>  
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alertType == 'success' ? 'success' : 'error'}>{alertMessage}</Alert>
            </Snackbar>
            {isOpenSymbolChartDialog &&
                <SymbolLiveChartDialog
                    open={isOpenSymbolChartDialog}
                    dialogClose={handleSymbolChartDialogClose}
                    symbol={selectedSymbol}
                />
            }
        </Fragment>                  
    )
}