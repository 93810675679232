import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Badge, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import { getDashboardExchange } from '../http';
import { useAuth } from '../auth';
import { Broker_Exchange_Image_Data } from '../components/helpers';

// Define types for broker data
interface BrokerData {
  broker: string;
  api: number;
  image?: string;
}

const StyledContainer = styled(Container)<{ dynamicHeight: boolean; containerHeight: number }>`
  margin-top: ${({ theme }) => theme.spacing(0)};
  margin-bottom: 20px;
  height: ${({ dynamicHeight, containerHeight }) => dynamicHeight ? `${containerHeight}px` : 'auto'}; 
  max-height: ${({ dynamicHeight }) => dynamicHeight ? '320px' : 'auto'};
  overflow-y: ${({ dynamicHeight }) => dynamicHeight ? 'auto' : 'visible'}; 
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95%;
  border-radius: 8%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  background-color: #ffffff;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    background-color: #2aaaf5;    
  }

  &::before {
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    background: linear-gradient(45deg, #2aaaf5, #ffffff);
    z-index: -1;
    transform: skew(-30deg);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);    
  }
`;

const FirmLogo = styled('img')`
  width: 70px;
  height: 70px;
  object-fit: contain;
  transform: translateY(-26px);
`;

const FirmName = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  transform: translateY(-26px);
`;

const FirmDescription = styled(Typography)`
  font-size: 14px;
  color: #777777;
  transform: translateY(-26px);
  margin-bottom: 0;
`;

const MyBadge = styled(Badge)`
  position: absolute;
  width: 50px;
  top: 10px;
  right: 10px;
  border-radius: 10px;
  padding: 2px 4px;
  font-family: 'verdana', cursive;
  font-size: 8px;
  background-color: #00b3b3;
  color: #ffffff;
  font-weight: bold;
`;

const BrokeragePage: React.FC = () => {
  const token = useAuth().auth?.token;
  const [brokerData, setBrokerData] = useState<BrokerData[]>([]);

  const cardHeight = 320; // height of a single card (adjust if necessary)
  const itemsPerRow = 4; // Number of items per row
  const maxVisibleItems = 4; // Maximum items to show before scrolling

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardExchange(token, "client-exchange/dashboard-exchange");
        if (response?.status === 200) {
          const updatedBrokerData: BrokerData[] = Object.entries(response.data).map(([broker, api]): BrokerData => ({ broker, api: api as number })).sort((a, b) => b.api - a.api);
          setBrokerData(updatedBrokerData);
        }
      } catch (error:any) {
        console.log("Error fetching brokerage data:", error?.response?.data?.apierror?.message);
      }
    };
    fetchData();
  }, []);



  const updatedBrokerData = brokerData.map(item => ({
    ...item,
    image: Broker_Exchange_Image_Data.filter(brokerData => brokerData.name === item.broker)[0]?.imageURL
  }));

  const numRows = Math.ceil(updatedBrokerData.length / itemsPerRow);
  const containerHeight = numRows * cardHeight;

  // Check if scrolling is needed based on the number of items
  const dynamicHeight = updatedBrokerData.length > maxVisibleItems;

  return (
    <StyledContainer dynamicHeight={dynamicHeight} containerHeight={containerHeight}>
      <Grid container spacing={0.7}>
        {updatedBrokerData.map(({ broker, api, image }) => (
          <Grid item xs={12} sm={6} md={3} key={broker}>
            <StyledCard>
              <CardContent>
                <FirmLogo src={image} alt={`${broker} Logo`} />
                <FirmName variant="h6">{broker}</FirmName>
                <FirmDescription variant="body2">Online Discount Brokerage</FirmDescription>
              </CardContent>
              <MyBadge>
                {api === 1 ? `${api} Active Account` : `${api} Active Accounts`}
              </MyBadge>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </StyledContainer>
  );
};

export default BrokeragePage;
