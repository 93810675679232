/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { MarketOverview, MarketOverviewTab } from 'react-ts-tradingview-widgets';
import BrokeragePage from '../../modules/dashboardExchange/BrokeragePage';
//import UserDashboardLayout from './userDashboard/UserDashboardLayout'
import MarketDataView from './userDashboard/MarketDataView';
import { StrategyStatistics } from './userDashboard/StrategyStatistics';
import { UserActivity } from './userDashboard/UserActivity';
import { AlgoActivity } from './userDashboard/AlgoActivity';
import { ProfitLoss } from './userDashboard/ProfitLoss';
import UserDashboardLayout from './userDashboard/UserDashboardLayout';
import { auto } from '@popperjs/core';

const defaultTabs: MarketOverviewTab[] = [
    {
        title: "Indices",
        symbols: [
            {
                "s": "BSE:SENSEX",
                "d": "SENSEX"
            },
            {
                "s": "NSE:NIFTY",
                "d": "NIFTY 50"
            },
            {
                "s": "NSE:BANKNIFTY",
                "d": "NIFTY BANK"
            },
            {
                "s": "NSE:CNXMIDCAP",
                "d": "NIFTY MIDCAP"
            },
            {
                "s": "NSE:CNXSMALLCAP",
                "d": "NIFTY SMALLCAP"
            },
            {
                "s": "NSE:CNXIT",
                "d": "NIFTY IT"
            },

        ],
        originalTitle: "Indices",
    },
    {
        title: "Commodities",
        symbols: [
            {
                s: "CME_MINI:ES1!",
                d: "S&P 500",
            },
            {
                s: "CME:6E1!",
                d: "Euro",
            },
            {
                s: "COMEX:GC1!",
                d: "Gold",
            },
            {
                s: "NYMEX:CL1!",
                d: "Crude Oil",
            },
            {
                s: "NYMEX:NG1!",
                d: "Natural Gas",
            },
            {
                s: "CBOT:ZC1!",
                d: "Corn",
            },
        ],
        originalTitle: "Commodities",
    },
    {
        "title": "Bonds",
        "symbols": [
            {
                "s": "TVC:IN01",
                "d": "IN01"
            },
            {
                "s": "TVC:IN05",
                "d": "IN05"
            },
            {
                "s": "TVC:IN10Y",
                "d": "IN10Y"
            },
            {
                "s": "TVC:IN10",
                "d": "IN10"
            },
            {
                "s": "TVC:IN15",
                "d": "IN15"
            },
            {
                "s": "TVC:IN30",
                "d": "IN30"
            },
        ],
        "originalTitle": "Bonds"
    },
    {
        "title": "Forex",
        "symbols": [
            {
                "s": "FX_IDC:INRCAX",
                "d": "INRCAX"
            },
            {
                "s": "FX_IDC:INRAUD",
                "d": "INRAUD"
            },
            {
                "s": "FX_IDC:INRUSD",
                "d": "INRUSD"
            },
            {
                "s": "FX_IDC:INREUR",
                "d": "INREUR"
            },
            {
                "s": "FX_IDC:INRGBP",
                "d": "INRGBP"
            },
            {
                "s": "FX_IDC:INRJPY",
                "d": "INRJPY"
            }
        ],
        "originalTitle": "Forex"
    },
];

const DashboardPage: FC = () => (
    <>
        <div className='row gy-5 g-xl-8'>
            <div className='col-xxl-4 mb-1'>
                <StrategyStatistics
                    className='card-xxl-stretch'
                    chartColor='danger'
                    chartHeight='200px'
                    strokeColor='#cb1e46'
                />
            </div>
            <div className='col-xxl-4 mb-1'>
                {/*  <UserDashboardLayout />*/}
                <AlgoActivity className='card-xxl-stretch' cardHeight='175px' />
            </div>
            <div className='col-xxl-4 mb-1'>                
                <UserActivity className='card-xxl-stretch' />
            </div>            
        </div>
        <div className='row gy-5 gx-xl-8'>               
            <div className='col-xxl-4 mt-1'>
                <ProfitLoss
                    className='card-xxl-stretch-80'
                    chartColor='primary'
                    chartHeight='175px'
                />
            </div>
            <div className='col-xxl-8'>
                <BrokeragePage />
            </div>
        </div>
        {/* <div className='row gy-5 gx-xl-8 mt-1 mb-2'>
            <div className='col-xxl-12'>
                <BrokeragePage />
            </div>
        </div> */}
        <div className='row gy-5 gx-xl-8 mb-2'>
            <div className='col-xxl-12'>
                <MarketDataView />
            </div>
        </div>
    </>
)

const UserDashboardWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <DashboardPage />
        </>
    )
}

export { UserDashboardWrapper }
