import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Strategy } from '../interfaces/Strategy'
import {
    getStockAnalysisData
} from '../http'

import {
    getStockHistoryData
} from '../http'

interface StockAnalysisState {
    stockAnalysisData: any | null
    loading: boolean
    errors: any
    stockHistoryData: any | null
    stockHistoryLoading: boolean
    stockHistoryErrors: any
}

const initialState: StockAnalysisState = {
    stockAnalysisData: null,
    loading: false,
    errors: null,
    stockHistoryData: null,
    stockHistoryLoading: false,
    stockHistoryErrors: null
}

export const getStockAnalysis = createAsyncThunk(
    'stockAnalysis/getStockAnalysis',
    async ({ data }: { data: any }, thunkAPI) => {
        try {
            const { token, body } = data
            const response = await getStockAnalysisData(token, "user-multi-exchange/stock-history", body);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getStockHistory = createAsyncThunk(
    'stockHistory/getStockHistory',
    async ({ data }: { data: any }, thunkAPI) => {
        try {
            const { token, body } = data
            const response = await getStockHistoryData(token, "stock/", body);
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

// reducers -> reduce to a specific state -> changes state

export const stockAnalysisSlice = createSlice({
    name: 'getStockAnalysis',
    initialState,
    reducers: {
        setStockAnalysis: (state, action: PayloadAction<any>) => {
            state.stockAnalysisData = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStockAnalysis.pending, (state, action) => {
            state.loading = true
            state.stockAnalysisData = null
            state.errors = null
        })
        builder.addCase(getStockAnalysis.fulfilled, (state, action) => {
            state.loading = false
            state.errors = null
            state.stockAnalysisData = action.payload
        })
        builder.addCase(getStockAnalysis.rejected, (state, action) => {
            state.loading = false
            state.stockAnalysisData = null
            state.errors = action.payload
        })
        builder.addCase(getStockHistory.pending, (state, action) => {
            state.stockHistoryLoading = true
            state.stockHistoryData = null
            state.stockHistoryErrors = null
        })
        builder.addCase(getStockHistory.fulfilled, (state, action) => {
            state.stockHistoryLoading = false
            state.stockHistoryErrors = null
            state.stockHistoryData = action.payload
        })
        builder.addCase(getStockHistory.rejected, (state, action) => {
            state.stockHistoryLoading = false
            state.stockHistoryData = null
            state.stockHistoryErrors = action.payload
        })
    },
})

export default stockAnalysisSlice.reducer
export const { setStockAnalysis } = stockAnalysisSlice.actions
