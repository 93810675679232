import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Indicies} from '../interfaces/Indicies'
import {getAllIndicies, createIndicies as postIndicies, editIndicies as putIndicies} from '../http'

interface IndiciesState {
  getIndiciesData: Indicies | null
  getLoading: boolean
  getErrors: any
  createIndiciesData: Indicies | null
  createLoading: boolean
  createErrors: any
  editIndiciesData: Indicies | null
  editLoading: boolean
  editErrors: any
}

const initialState: IndiciesState = {
  getIndiciesData: null,
  getLoading: false,
  getErrors: null,
  createIndiciesData: null,
  createLoading: false,
  createErrors: null,
  editIndiciesData: null,
  editLoading: false,
  editErrors: null,
}

export const getIndicies = createAsyncThunk(
  'indicies/getIndicies',
  async ({token}: {token: any;}, thunkAPI) => {
    try {     
      const response = await getAllIndicies(token, 'indices-master/all')
      return response.data
    } catch (error) {      
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const createIndicies = createAsyncThunk(
  'indicies/createIndicies',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data      
      const response = await postIndicies(token, 'indices-master/', body)
      return response.data
    } catch (error) {     
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const editIndicies = createAsyncThunk(
  'indicies/editIndicies',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data      
      const response = await putIndicies(token, `indices-master/${body.id}`, body)
      return response.data
    } catch (error) {      
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const indiciesSlice = createSlice({
  name: 'getIndicies',
  initialState,
  reducers: {
    setIndicies: (state, action: PayloadAction<Indicies>) => {
      state.getIndiciesData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIndicies.pending, (state, action) => {
      state.getLoading = true
      state.getIndiciesData = null
    })
    builder.addCase(getIndicies.fulfilled, (state, action) => {
      state.getLoading = false
      state.getIndiciesData = action.payload
      state.getErrors = null
    })
    builder.addCase(getIndicies.rejected, (state, action) => {
      state.getLoading = false
      state.getErrors = action.payload
      state.getIndiciesData = null
    })
    builder.addCase(createIndicies.pending, (state, action) => {
      state.createLoading = true
      state.createIndiciesData = null
    })
    builder.addCase(createIndicies.fulfilled, (state, action) => {
      state.createLoading = false
      state.createIndiciesData = action.payload
      state.createErrors = null
    })
    builder.addCase(createIndicies.rejected, (state, action) => {
      state.createLoading = false
      state.createErrors = action.payload
      state.createIndiciesData = null
    })
    builder.addCase(editIndicies.pending, (state, action) => {
      state.editLoading = true
      state.editIndiciesData = null
    })
    builder.addCase(editIndicies.fulfilled, (state, action) => {
      state.editLoading = false
      state.editIndiciesData = action.payload
      state.editErrors = null
    })
    builder.addCase(editIndicies.rejected, (state, action) => {
      state.editLoading = false
      state.editErrors = action.payload
      state.editIndiciesData = null
    })
  },
})

export default indiciesSlice.reducer
export const {setIndicies} = indiciesSlice.actions
