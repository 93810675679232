import React, { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { SendOtpCard } from './SendOtpCard';
import { ResetPasswordCard } from './ResetPasswordCard';
//import {requestPassword} from '../core/_requests'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .max(50, 'Email should be maximum 50 characters')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [sendOtpScreen, setSendOtpScreen] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailId, setEmailId] = useState('');
  const [verificationReqId, setVerificationReqId] = useState(0);

  const handleClose = (value) => {
    const { phoneNo, email, otpVerificationId, isShowResetPwdScreen } = value;
    setSendOtpScreen(!isShowResetPwdScreen);
    setPhoneNumber(phoneNo);
    setEmailId(email);
    setVerificationReqId(otpVerificationId);
  }  

  return (
    <>
      {sendOtpScreen ?
        <SendOtpCard onClose={handleClose} />
        :
        <ResetPasswordCard phoneNumber={phoneNumber} email={emailId} verficationId={verificationReqId} />
      }
    </>
  )
}
