import { useEffect, useState } from 'react';
import { Dialog, DialogContent, IconButton, AppBar, Container, Toolbar, Avatar, Typography, Card, CardContent } from '@mui/material'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';


export type IDataProps = {
  open: boolean
  symbol: string,
  dialogClose: () => void,
}

export function SymbolLiveChartDialog(props: IDataProps) {
  const { open, dialogClose, symbol } = props

  const [showSymbol, setShowSymbol] = useState<string>(symbol);
  const [chartKey, setChartKey] = useState<number>(0);
  const [dialogView, setDialogView] = useState<'md' | 'lg'>('lg');
  const [isDialogMaxView, setIsDialogMaxView] = useState<boolean>(true);
  const [chartHeight, setChartHeight] = useState<string>('400px');


  useEffect(() => {
    if (symbol) {
      setShowSymbol(symbol);
      setChartKey((prevKey) => prevKey + 1);
    }
  }, [symbol]);

  const handleDialogView = (option: any) => {
    setIsDialogMaxView(!isDialogMaxView);
    setChartHeight(option === 'lg' ? '600px' : '400px');    
    setDialogView(option);
  }  

  return (
    <Dialog
      open={open}
      fullWidth={true}
      // fullScreen={true}
      maxWidth={dialogView}
      keepMounted
      onClose={dialogClose}
      
    // aria-describedby="alert-dialog-slide-description"
    >
      <AppBar
        position="static"
        elevation={0}
        // color='#212A47'
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, bgcolor: '#1C202E' }}
      >
        <Container maxWidth='xl'>
          <Toolbar variant='dense' disableGutters>
            <Avatar variant='rounded' src={toAbsoluteUrl('/media/logos/Til_logo.jpg')} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, width: 30, height: 30 }} />
            <Typography
              variant='h5'
              component='div'
              sx={{
                flexGrow: 1,
                textAlign: 'center'
              }}
            >
              TIL Symbol Chart
            </Typography>
            {isDialogMaxView ?
              <IconButton
                color='info'
                size='small'
                className='mt-0 pt-0'
                onClick={() => handleDialogView('md')}
              >
                <FullscreenExitIcon fontSize='medium' />
              </IconButton>
              :
              <IconButton
                color='info'
                size='small'
                className='mt-0 pt-0'
                onClick={() => handleDialogView('lg')}
              >
                <FullscreenIcon fontSize='medium' />
              </IconButton>
            }
            <IconButton onClick={dialogClose}><CloseSharpIcon fontSize='medium' sx={{ color: 'white' }} /> </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      <DialogContent dividers sx={{ height: "585px", overflow: "hidden", padding: '5px', margin: '5px' }}>      
        {/* <Card style={{height: '595px', padding: '0px', margin: '0px'}}>
          <CardContent> */}
            {/* <div className='row' style={{ height: '600px', padding: '0px' }}>
              <div className='col-lg-12 mt-2'> */}
                {showSymbol && (
                  <AdvancedRealTimeChart
                    // key={chartKey}
                    symbol={showSymbol === null || showSymbol === '' ? 'Sensex' : showSymbol}
                    timezone='exchange'
                    theme='light'
                    locale='en'
                    interval='D'
                    enable_publishing={true}
                    withdateranges={true}
                    hide_legend={true}
                    hide_top_toolbar={false}
                    hide_side_toolbar={false}
                    allow_symbol_change={true}
                    details={true}
                    calendar={true}
                    hotlist={true}                    
                    // height={chartHeight.toString()}
                    height='600px'
                    width='100%'
                  />
                )}
              {/* </div>
            </div> */}
          {/* </CardContent>
        </Card> */}
      </DialogContent>      
    </Dialog>
  )
}
