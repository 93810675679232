import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import moment from 'moment';
import { getCSSVariableValue } from '../../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { useAppDispatch, useAppSelector } from '../../../../Store'
import { useAuth } from '../../../modules/auth'
import { getAlgoSignalProcessingData } from '../../../modules/reducers/algoSignalProcessingSlice';
import { getAllAlgoSignalProcessing } from '../../../modules/http';
import { CurrencyFormater } from '../../../modules/components/CurrencyFormater';

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const ProfitLoss: React.FC<Props> = ({ className, chartColor, chartHeight }) => {
  const dispatch = useAppDispatch();
  const currentDate = moment().format('YYYY-MM-DD');
  const today = moment().format('MMM Do YY');
  const lastWeek = moment().subtract(6, 'days').format('YYYY-MM-DD');
  const lastMonth = moment().subtract(1, 'month').format('YYYY-MM-DD');
  const fromD = moment().subtract(2, 'days').format('YYYY-MM-DD');
  const token = useAuth().auth?.token || '';
  const userId = useAuth().auth?.token;
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const [activeTab, setActiveTab] = useState('day')
  const [dateRange, setDateRange] = useState<string>('')
  const [fromDate, setFromDate] = useState<any>(currentDate);
  const [toDate, setToDate] = useState<any>(currentDate);
  const [profitLoss, setProfitLoss] = useState<any[]>([]);
  const [createdDate, setCreatedDate] = useState<any[]>([]);
  const { algoSignalProcessMaster, loading } = useAppSelector((state) => state.algoSignalProcessing)

  useEffect(() => {
    // Update date range based on active tab
    if (activeTab === 'day') {
      setDateRange(today);
    } else if (activeTab === 'week') {
      const lastWeekStart = moment().subtract(6, 'days').format('MMM Do YY');
      setDateRange(`${lastWeekStart} - ${today}`);
    } else if (activeTab === 'Month') {
      const lastMonthStart = moment().subtract(1, 'month').format('MMM Do YY');
      setDateRange(`${lastMonthStart} - ${today}`);
    }
  }, [activeTab]);

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, profitLoss, createdDate))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, profitLoss])

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    if (tab === 'Month') {
      handleMonthFilterTab();
    } else if (tab === 'week') {
      handleWeekFilterTab();
    } else if (tab === 'day') {
      handleDayFilterTab();
    }
  };

  const dateWiseDetail = {};

  const callAlgoSignalApi = async (fromDate: any, toDate: any) => {
    try {
      const response = await getAllAlgoSignalProcessing(token, `transaction-master/bydate?fromDate=${fromDate}&toDate=${toDate}&details=${false}`);
      if (response?.status === 200) {
        const data = response?.data?.modal?.data || [];
        data.forEach((item) => {
          const date = moment(item?.createdDate).format("DD MMM YY");
          if (item && dateWiseDetail[date]) {
            dateWiseDetail[date]?.push(item)
          } else if (item) {
            if (!date || date === 'null') {
              if (dateWiseDetail?.['N/A']) {
                dateWiseDetail['N/A']?.push(item)
              } else {
                dateWiseDetail['N/A'] = item
              }
            } else {
              dateWiseDetail[date] = [item]
            }
          }
        });

        const dateKeys = Object.keys(dateWiseDetail)
        const sortedDates = dateKeys.sort((a, b) => {
          const dateA: any = new Date(a.split("-").reverse().join("-"));
          const dateB: any = new Date(b.split("-").reverse().join("-"));
          return dateA - dateB;
        });
        accumulateProfitAndLoss(dateWiseDetail, sortedDates)
        setCreatedDate(sortedDates);
      }

    } catch (error) {
      console.log(error);
    }
  }  

  const accumulateProfitAndLoss = (item: any, keyArray: any) => {
    const totalProfitLoss: any = [];
    for (let i = 0; i < keyArray.length; i += 1) {
      const currentDate = keyArray[i];
      const currentDateData = item?.[currentDate] ?? [];
      let profitLoss: any = currentDateData?.reduce((accum, item) => Number(Number(Number(accum) +
        Number(item.lot)).toFixed(2)), 0);     

      totalProfitLoss.push(profitLoss.toString());
    }
    setProfitLoss(totalProfitLoss);
  }


  useEffect(() => {
    callAlgoSignalApi(fromDate, toDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDayFilterTab = () => {
    setFromDate(currentDate);
    setToDate(currentDate);
    callAlgoSignalApi(currentDate, currentDate);
  }

  const handleWeekFilterTab = () => {
    setFromDate(lastWeek);
    setToDate(currentDate);
    callAlgoSignalApi(lastWeek, currentDate);
  }

  const handleMonthFilterTab = () => {
    setFromDate(lastMonth);
    setToDate(currentDate);
    callAlgoSignalApi(lastMonth, currentDate);
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Toolbar */}
      <div className='card-toolbar mt-5 ms-5'>
        <ul className='nav'>
          <li className='nav-item'>
            <button
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${activeTab === 'Month' ? 'active' : ''}`}
              data-bs-toggle='tab'
              onClick={() => handleTabClick('Month')}
            >
              Month
            </button>
          </li>
          <li className='nav-item'>
            <button
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${activeTab === 'week' ? 'active' : ''}`}
              data-bs-toggle='tab'
              onClick={() => handleTabClick('week')}
            >
              Week
            </button>
          </li>
          <li className='nav-item'>
            <button
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${activeTab === 'day' ? 'active' : ''}`}
              data-bs-toggle='tab'
              onClick={() => handleTabClick('day')}
            >
              Day
            </button>
          </li>
        </ul>
      </div>
      {/* end::Toolbar */}

      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bold text-gray-800 d-block fs-3'>Profit and Loss</span>
            <span className='text-gray-400 fw-semibold fs-8'>{dateRange}</span>
          </div>
        </div>
        {/* end::Hidden */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, profitLoss: any, createdDate: any): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')
  const baseColor = getCSSVariableValue('--kt-' + chartColor)

  return {
    series: [
      {
        name: 'Profit',
        data: profitLoss,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: createdDate,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '10px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '10px',
        },
      },
    },
    fill: {
      type: 'solid',
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '10px',
      },
      y: {
        formatter: function (val) {
          return CurrencyFormater(val || '0', 'INR', true).toString()
          // return '$' + val + ' revenue'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      padding: {
        top: 5,
        bottom: 25
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export { ProfitLoss }
