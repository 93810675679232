import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { UserDashboardWrapper } from '../pages/dashboard/UserDashboardWrapper'
import { useAuth } from '../../app/modules/auth'
import { Ticket } from '../pages/ticket/Ticket'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const ReferralPage = lazy(() => import('../modules/referral/ReferralPage'))
  const StrategyPage = lazy(() => import('../modules/strategy/StrategyPage'))
  const ClientExchangePage = lazy(() => import('../modules/clientExchange/ClientExchangePage'))
  const SubscriptionPage = lazy(() => import('../modules/subscription/SubscriptionPage'))
  const UserLiveStrategy = lazy(() => import('../modules/userLiveStrategy/UserLiveStrategyPage'))
  const ExchangePage = lazy(() => import('../modules/Exchange/ExchangePage'))
  const MultiUserExchange = lazy(() => import('../modules/multiUserExchange/MultiUserExchangePage'))
  const RewardPointCategory = lazy(() => import('../modules/RewardPointCategory/RewardPointCategoryPage'))
  const RewardPointPlan = lazy(() => import('../modules/RewardPointPlan/RewardPointPlanPage'))
  const RewardPointValue = lazy(() => import('../modules/RewardPointValue/RewardPointValuePage'))
  const SubscriptionPlan = lazy(() => import('../modules/subscriptionPlan/SubscriptionPlanPage'))
  const DiscountCoupon = lazy(() => import('../modules/DiscountCoupon/DiscountCouponPage'))
  const OrderInvoice = lazy(() => import('../modules/Invoice/InvoicePage'))
  const FyersExchangeCallback = lazy(() => import('../modules/exchangeCallback/FyersExchangeCallback'))
  const FlatTradeExchangeCallback = lazy(() => import('../modules/exchangeCallback/FlatTradeExchangeCallback'))
  const PaytmMoneyExchangeCallback = lazy(() => import('../modules/exchangeCallback/PaytmMoneyExchangeCallback'))
  const AlpcaExchangeCallback = lazy(() => import('../modules/exchangeCallback/AlpcaExchangeCallback'))
  const AngelExchangeCallback = lazy(() => import('../modules/exchangeCallback/AngelExchangeCallback'))
  const UpstoxExchangeCallback = lazy(() => import('../modules/exchangeCallback/UpstoxExchangeCallback'))
  const ICICIDirectExchangeCallback = lazy(() => import('../modules/exchangeCallback/ICICIDirectExchangeCallback'))
  const FivePaisaExchangeCallback = lazy(() => import('../modules/exchangeCallback/FivePaisaExchangeCallback'))
  const SharekhanExchangeCallback = lazy(() => import('../modules/exchangeCallback/SharekhanExchangeCallback'))
  const UserLiveStrategyForPaperTrading = lazy(() => import('../modules/userLiveStrategyForPaperTrading/UserLiveStrategyForPaperTradingPage'))
  const MotilalOswalExchangeCallback = lazy(() => import('../modules/exchangeCallback/MotilalOswalExchangeCallback'))
  const StockHistoryPage = lazy(() => import('../modules/stockHistory/StockHistoryPage'))
  const StockAnalysisPage = lazy(() => import('../modules/stockAnalysis/StockAnalysisPage'))
  const ZerodhaExchangeCallback = lazy(() => import('../modules/exchangeCallback/ZerodhaExchangeCallback'))
  const IndiciesPage = lazy(() => import('../modules/indicies/IndiciesPage'))
  const LiveAlgoTransactionHistoryPage = lazy(() => import('../modules/liveAlgoTransactionHistory/LiveAlgoTransactionHistoryPage'))
  const LiveAlgoTransactionExchangePage = lazy(() => import('../modules/liveAlgoTransactionExchange/LiveAlgoTransactionExchangePage'))
  const CrossReferencePage = lazy(() => import('../modules/crossReference/CrossReferencePage'))
  const IndicatorPage = lazy(() => import('../modules/indicator/IndicatorPage'))
  const StrategyIndicatorMappingPage = lazy(() => import('../modules/strategyIndicatorMapping/StrategyIndicatorMappingPage'))
  const StrategyIndicatorConfigAdaptorPage = lazy(() => import('../modules/strategyIndicatorConfigAdaptor/StrategyIndicatorConfigAdaptorPage'))

  const user_Role = useAuth().auth?.roles[0];
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {user_Role === "ROLE_ADMIN" ?
          <>
            <Route path='auth/*' element={<Navigate to='/dashboard' />} />

            <Route path='dashboard' element={<DashboardWrapper />} />
          </>
          : <>
            <Route path='auth/*' element={<Navigate to='/dashboard' />} />
            <Route path='dashboard' element={<UserDashboardWrapper />} />
          </>
        }

        {/* Pages */}
        {/* <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='user-dashboard' element={<UserDashboardWrapper />} />*/}
        <Route path='api/ticket/number' element={<Ticket />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='referral/*'
          element={
            <SuspensedView>
              <ReferralPage />
            </SuspensedView>
          }
        />
        <Route
          path='strategy/*'
          element={
            <SuspensedView>
              <StrategyPage />
            </SuspensedView>
          }
        />
        <Route
          path='client-exchange/*'
          element={
            <SuspensedView>
              <ClientExchangePage />
            </SuspensedView>
          }
        />
        <Route
          path='subscription/*'
          element={
            <SuspensedView>
              <SubscriptionPage />
            </SuspensedView>
          }
        />
        <Route
          path='user-live-strategy/*'
          element={
            <SuspensedView>
              <UserLiveStrategy />
            </SuspensedView>
          }
        />
        <Route
          path='live-algo-transaction-history/*'
          element={
            <SuspensedView>
              <LiveAlgoTransactionHistoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='live-algo-transaction-exchange/*'
          element={
            <SuspensedView>
              <LiveAlgoTransactionExchangePage />
            </SuspensedView>
          }
        />
        <Route
          path='cross-reference/*'
          element={
            <SuspensedView>
              <CrossReferencePage />
            </SuspensedView>
          }
        />
        <Route
          path='user-live-strategy-paper/*'
          element={
            <SuspensedView>
              <UserLiveStrategyForPaperTrading />
            </SuspensedView>
          }
        />
        <Route
          path='exchange/*'
          element={
            <SuspensedView>
              <ExchangePage />
            </SuspensedView>
          }
        />
        <Route
          path='alpca-exchange-callback/*'
          element={
            <SuspensedView>
              <AlpcaExchangeCallback />
            </SuspensedView>
          }
        />
        <Route
          path='fyers-exchange-callback/*'
          element={
            <SuspensedView>
              <FyersExchangeCallback />
            </SuspensedView>
          }
        />
        <Route
          path='flattrade-exchange-callback/*'
          element={
            <SuspensedView>
              <FlatTradeExchangeCallback />
            </SuspensedView>
          }
        />
        <Route
          path='paytm-money-exchange-callback/*'
          element={
            <SuspensedView>
              <PaytmMoneyExchangeCallback />
            </SuspensedView>
          }
        />
        <Route
          path='angel-exchange-callback/*'
          element={
            <SuspensedView>
              <AngelExchangeCallback />
            </SuspensedView>
          }
        />
        <Route
          path='upstox-exchange-callback/*'
          element={
            <SuspensedView>
              <UpstoxExchangeCallback />
            </SuspensedView>
          }
        />
        <Route
          path='icici-direct-exchange-callback/*'
          element={
            <SuspensedView>
              <ICICIDirectExchangeCallback />
            </SuspensedView>
          }
        />
        <Route
          path='five-paisa-exchange-callback/*'
          element={
            <SuspensedView>
              <FivePaisaExchangeCallback />
            </SuspensedView>
          }
        />
        <Route
          path='sharekhan-exchange-callback/*'
          element={
            <SuspensedView>
              <SharekhanExchangeCallback />
            </SuspensedView>
          }
        />
        <Route
          path='motilal-oswal-exchange-callback/*'
          element={
            <SuspensedView>
              <MotilalOswalExchangeCallback />
            </SuspensedView>
          }
        />
        <Route
          path='zerodha-exchange-callback/*'
          element={
            <SuspensedView>
              <ZerodhaExchangeCallback />
            </SuspensedView>
          }
        />
        <Route
          path='multi-user-exchange/*'
          element={
            <SuspensedView>
              <MultiUserExchange />
            </SuspensedView>
          }
        />
        <Route
          path='reward-point-category/*'
          element={
            <SuspensedView>
              <RewardPointCategory />
            </SuspensedView>
          }
        />
        <Route
          path='reward-point-plan/*'
          element={
            <SuspensedView>
              <RewardPointPlan />
            </SuspensedView>
          }
        />
        <Route
          path='reward-point-value/*'
          element={
            <SuspensedView>
              <RewardPointValue />
            </SuspensedView>
          }
        />
        <Route
          path='subscription-plan/*'
          element={
            <SuspensedView>
              <SubscriptionPlan />
            </SuspensedView>
          }
        />
        <Route
          path='discount-coupon/*'
          element={
            <SuspensedView>
              <DiscountCoupon />
            </SuspensedView>
          }
        />
        <Route
          path='order-invoice/*'
          element={
            <SuspensedView>
              <OrderInvoice />
            </SuspensedView>
          }
        />
        <Route
          path='stock-analysis/*'
          element={
            <SuspensedView>
              <StockAnalysisPage />
            </SuspensedView>
          }
        />
        <Route
          path='stock-history/*'
          element={
            <SuspensedView>
              <StockHistoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='indicies/*'
          element={
            <SuspensedView>
              <IndiciesPage />
            </SuspensedView>
          }
        />
        <Route
          path='indicator/*'
          element={
            <SuspensedView>
              <IndicatorPage />
            </SuspensedView>
          }
        />
        <Route
          path='strategyIndicatorMapping/*'
          element={
            <SuspensedView>
              <StrategyIndicatorMappingPage />
            </SuspensedView>
          }
        />
        <Route
          path='strategyIndicatorConfigAdaptor/*'
          element={
            <SuspensedView>
              <StrategyIndicatorConfigAdaptorPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
