import axios from 'axios'
import {UserProfileModel} from './_models'
import { v4 as uuidv4 } from 'uuid';

const DEV_API_BASE_URL = process.env.REACT_APP_API_ENDPOINT
const LOCAL_API_BASE_URL = process.env.REACT_APP_API_LOCAL_BASE_URL
const LOGIN_API_URL = process.env.REACT_APP_API_LOGIN_URL

// export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
// export const LOGIN_URL = `${API_URL}/login`
// export const New_LOGIN_URL = `${LOGIN_API_URL}login`
// export const REGISTER_URL = `${API_URL}/register`
// export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  // const login_Url = 'http://34.100.138.134:8081/api/user/login'
  // const login_Url = 'http://localhost:8081/api/auth/signin'
  const login_Url = `${DEV_API_BASE_URL}auth/signin`
  const deviceId = uuidv4();
  localStorage.setItem("deviceId", deviceId);
  return axios.post(login_Url, {
    username: email,
    password: password,
    deviceInfo: {
      deviceId: deviceId,
      deviceType: "web"
    }
  })
}

export function googleSignIn(code: string, referralCode: string) {  
  const googleSignIn_Url = `${DEV_API_BASE_URL}google-sign-in`
  const deviceId = uuidv4();
  localStorage.setItem("deviceId", deviceId);
  return axios.post(googleSignIn_Url, {
    code: code,
    referralCode: referralCode,
    deviceInfo: {
      deviceId: deviceId,
      deviceType: "web"
    }
  })
}

export function getUserByToken(userId: number, token: string) {
  //return axios.get<UserProfileModel>("http://localhost:8085/api/userprofile/get?userId=" + `${userId}`, {
  //return axios.get<UserProfileModel>(`http://3.109.34.70:8081/api/userprofile/get?userId= + ${userId}`, {
  return axios.get<UserProfileModel>(`${DEV_API_BASE_URL}userprofile/`, {
  // return axios.get<UserProfileModel>(`http://localhost:8081/api/userprofile/`, {
    headers: {
      Authorization: token,
    },
  })
}
