import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RewardPointCategory} from '../interfaces/RewardPointCategory'
import {getAllRewardPointCategory, createRewardPointCategory as postRewardPointCategory, 
    editRewardPointCategory as putRewardPointCategory} from '../http'

interface RewardPointCategoryState {
  getRewardPointCategoryData: RewardPointCategory | null
  getLoading: boolean
  getErrors: any
  createRewardPointCategoryData: RewardPointCategory | null
  createLoading: boolean
  createErrors: any
  editRewardPointCategoryData: RewardPointCategory | null
  editLoading: boolean
  editErrors: any
}

const initialState: RewardPointCategoryState = {
  getRewardPointCategoryData: null,
  getLoading: false,
  getErrors: null,
  createRewardPointCategoryData: null,
  createLoading: false,
  createErrors: null,
  editRewardPointCategoryData: null,
  editLoading: false,
  editErrors: null,
}

export const getRewardPointCategory = createAsyncThunk(
  'rewardPointCategory/getRewardPointCategory',
  async ({token, userId}: {token: any; userId: any}, thunkAPI) => {
    try {     
      const response = await getAllRewardPointCategory(token, 'reward-point-category/all')
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const createRewardPointCategory = createAsyncThunk(
  'rewardPointCategory/createRewardPointCategory',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      // const response = await postBroker(token, "user-exchange/add", body);
      const response = await postRewardPointCategory(token, 'reward-point-category/', body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const editRewardPointCategory = createAsyncThunk(
  'rewardPointCategory/editRewardPointCategory',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      const response = await putRewardPointCategory(token, `reward-point-category/${body.id}`, body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const rewardPointCategorySlice = createSlice({
  name: 'getRewardPointCategory',
  initialState,
  reducers: {
    setRewardPointCategory: (state, action: PayloadAction<RewardPointCategory>) => {
      state.getRewardPointCategoryData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRewardPointCategory.pending, (state, action) => {
      state.getLoading = false
      state.getRewardPointCategoryData = null
    })
    builder.addCase(getRewardPointCategory.fulfilled, (state, action) => {
      state.getLoading = true
      state.getRewardPointCategoryData = action.payload
      state.getErrors = null
    })
    builder.addCase(getRewardPointCategory.rejected, (state, action) => {
      state.getLoading = false
      state.getErrors = action.payload
      state.getRewardPointCategoryData = null
    })
    builder.addCase(createRewardPointCategory.pending, (state, action) => {
      state.createLoading = true
      state.createRewardPointCategoryData = null
    })
    builder.addCase(createRewardPointCategory.fulfilled, (state, action) => {
      state.createLoading = true
      state.createRewardPointCategoryData = action.payload
      state.createErrors = null
    })
    builder.addCase(createRewardPointCategory.rejected, (state, action) => {
      state.createLoading = true
      state.createErrors = action.payload
      state.createRewardPointCategoryData = null
    })
    builder.addCase(editRewardPointCategory.pending, (state, action) => {
      state.editLoading = false
      state.editRewardPointCategoryData = null
    })
    builder.addCase(editRewardPointCategory.fulfilled, (state, action) => {
      state.editLoading = true
      state.editRewardPointCategoryData = action.payload
      state.editErrors = null
    })
    builder.addCase(editRewardPointCategory.rejected, (state, action) => {
      state.editLoading = true
      state.editErrors = action.payload
      state.editRewardPointCategoryData = null
    })
  },
})

export default rewardPointCategorySlice.reducer
export const {setRewardPointCategory} = rewardPointCategorySlice.actions
