import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from '../interfaces/User'
import { uploadProfileImage } from '../http'

interface ProfileImageState {
  getData: User | null
  loading: boolean
  errors: any
}

const initialState: ProfileImageState = {
  getData: null,
  loading: false,
  errors: null,
}

export const updateProfileImage = createAsyncThunk(
  'user/updateProfileImage',
  async ({ data }: { data: any }, thunkAPI) => {
    try {
      const { token, body } = data
      const response = await uploadProfileImage(token, 'userprofile/upload-file', body);
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const profileImageSlice = createSlice({
  name: 'updateProfileImage',
  initialState,
  reducers: {
    setProfileImage: (state, action: PayloadAction<User>) => {
      state.getData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateProfileImage.pending, (state, action) => {
      state.loading = true
      state.getData = null
    })
    builder.addCase(updateProfileImage.fulfilled, (state, action) => {
      state.loading = false
      state.getData = action.payload
      state.errors = null
    })
    builder.addCase(updateProfileImage.rejected, (state, action) => {
      state.loading = false
      state.errors = action.payload
      state.getData = null
    })
  },
})

export default profileImageSlice.reducer
export const { setProfileImage } = profileImageSlice.actions
