import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { resetPassword } from '../../http';
import bcrypt from 'bcryptjs';

export type IDataProps = {
    phoneNumber: string,
    email: string,
    verficationId: number
}

const initialValuesResetPassword = {
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: ''
};

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+~\-={}\[\]|\\:;"'<>,.?/])[A-Za-z\d!@#$%^&*()_+~\-={}\[\]|\\:;"'<>,.?/]{8,}$/;
const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .trim()
        .required('Password is required')
        .min(8, 'Password should be at least 8 characters.')
        .max(50, 'Password should be maximum 50 characters')
        .matches(passwordRegex, 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'),
    confirmPassword: Yup.string()
        .trim()
        .required('confirm Password is required')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
        }),
});

export function ResetPasswordCard(props: IDataProps) {
    const navigate = useNavigate();
    const { phoneNumber, email, verficationId } = props;

    const salt = bcrypt.genSaltSync(10);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState<string>('success');
    const [alertMessage, setAlertMessage] = useState('');

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const formik = useFormik({
        initialValues: initialValuesResetPassword,
        validationSchema: resetPasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            //   setHasErrors(undefined)
            const hashedPassword  = bcrypt.hashSync(values.password, salt);
            const hashedConfirmPassword  = bcrypt.hashSync(values.confirmPassword, salt);
            const body = {
                emailAddress: email,
                phoneNumber: phoneNumber,
                newpassword: values.password,
                confirmPassword: values.confirmPassword,
                verificationRequestId: verficationId
            }
            try {
                const sendOTPResponse: any = await resetPassword("auth/reset-password", body);
                if (sendOTPResponse?.status == 200) {
                    setOpenAlert(true);
                    setAlertMessage('Password has been changed successfully! Please login.');
                    setAlertType('success');
                    formik.resetForm();
                    navigate('/auth/sign-in');
                }
                else {
                    setOpenAlert(true);
                    setAlertMessage('Password reset not successfully! Please try again.');
                    setAlertType('error');
                }

            } catch (error: any) {
                setOpenAlert(true);
                setAlertMessage(error?.response?.data?.apierror?.message);
                setAlertType('error');
            }
            setLoading(false);
        },
    });

    return (
        <>
            <form
                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                noValidate
                id='kt_login_password_reset_form'
                onSubmit={formik.handleSubmit}
            >
                <div className='text-center mb-10'>
                    {/* begin::Title */}
                    <h1 className='text-dark mb-3'>Reset Password ?</h1>
                    {/* end::Title */}
                </div>

                {/* begin::Form group */}
                <div className='row fv-row mb-4' data-kt-password-meter='true'>
                    <div className='col-xl-6'>
                        <div className='position-relative mb-1'>
                            <TextField
                                required
                                size='medium'
                                label='Password'
                                className='form-control form-control-lg form-control-solid'
                                type={showPassword ? 'text' : 'password'}
                                fullWidth
                                autoComplete='off'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                {...formik.getFieldProps('password')}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert' className='fs-8'>{formik.errors.password}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* <div
                            className='d-flex align-items-center mb-1 mt-2'
                            data-kt-password-meter-control='highlight'
                        >
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                        </div> */}
                    </div>
                    <div className='col-xl-6'>
                        <TextField
                            required
                            size='medium'
                            type={showConfirmPassword ? 'text' : 'password'}
                            label='Confirm Password'
                            className='form-control form-control-lg form-control-solid'
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownConfirmPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            {...formik.getFieldProps('confirmPassword')}
                        />

                        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='fs-8'>{formik.errors.confirmPassword}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                    <Button
                        type='submit'
                        id='kt_password_reset_submit'
                        variant="contained"
                        color="info"
                        className='fw-bolder me-4'
                        size="large"
                    >
                        <span className='indicator-label'>Submit</span>
                        {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </Button>
                    <Link to='/auth/sign-in'>
                        <Button
                            variant="contained"
                            type='button'
                            color="inherit"
                            size="large"
                            id='kt_login_password_reset_form_cancel_button'
                        // disabled={formik.isSubmitting || !formik.isValid}
                        >
                            Cancel
                        </Button>
                    </Link>{' '}
                </div>
                {/* end::Form group */}
            </form>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity={alertType == 'success' ? 'success' : 'error'}>{alertMessage}</Alert>
            </Snackbar>
        </>
    )

}