import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useAuth } from '../../auth'
import { Data as UserModel } from '../../interfaces/User'
import { verifyOTP, sendOTP } from '../../http'
import { countryCode as countryCodeList } from '../../helpers';

export type IDataProps = {
  open: boolean
  id: number;
  phoneNo: string
  onClose: (value) => void
  signIn: boolean
}

const phoneNumberInitialData = {
  phoneNumber: '',
  countryObject: { countryCode: 'IN', label: 'India', phone: '+91' }
}

const otpInitialData: any = {
  otp: '',
}

const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
const phoneNumberFormValidationSchema = Yup.object().shape({
  countryObject: Yup.object().shape({
    countryCode: Yup.string().nullable().required('Country code is required'),
    label: Yup.string().nullable().required(),
    phone: Yup.string().nullable().required(),
  }),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Phone number is not valid'),
})

const otpFormValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .nullable()
    .required('OTP is required')
    .max(6, 'Otp must be 6 digits')
    .matches(/^\d+$/, "Otp should be numeric")
})

export function PhoneVerificationDialog(props: IDataProps) {
  const navigate = useNavigate();
  const token = useAuth().auth?.token || ''
  const { open, id, phoneNo, onClose, signIn } = props

  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(phoneNo);
  const [countryIsdCode, setCountryIsdCode] = useState<string>('');
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState<string>('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [seconds, setSeconds] = useState(60);
  const [verificationRequestId, setVerificationRequestId] = useState(0);


  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  const handleResendOTP = async () => {
    // code to resend OTP
    const body = {
      target: `${countryIsdCode}${phoneNumber}`,
      type: "SMS",
      purpose: "PHONE_VERIFICATION"
    }
    try {
      const sendOTPResponse: any = await sendOTP("auth/send/otp", body);
      if (sendOTPResponse?.data?.requestProcessed) {
        setVerificationRequestId(sendOTPResponse?.data?.id);
        setOpenAlert(true);
        setAlertMessage("OTP Resent Successfully.");
        setAlertType('success');
      }
      // setMinutes(1);
      setSeconds(60);
    } catch (error: any) {
      setOpenAlert(true);
      setAlertMessage(error?.response?.data?.apierror?.message);
      setAlertType('error');
    }

  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        // if (minutes === 0) {
        clearInterval(interval);
        // } else {
        //   setSeconds(59);
        //   setMinutes(minutes - 1);
        // }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const formik1 = useFormik({
    initialValues: phoneNumberInitialData,
    validationSchema: phoneNumberFormValidationSchema,
    onSubmit: async (values) => {
      setLoading1(true)

      const body = {
        target: `${values.countryObject.phone}${values.phoneNumber}`,
        type: "SMS",
        purpose: "PHONE_VERIFICATION"
      }
      try {
        const sendOTPResponse: any = await sendOTP("auth/send/otp", body)

        if (sendOTPResponse?.data?.requestProcessed) {
          setVerificationRequestId(sendOTPResponse?.data?.id);
          setOpenAlert(true);
          setAlertMessage(`OTO has been sent to your registered phone no.`);
          setAlertType('success');
        }

        setPhoneNumber(values.phoneNumber)
        setCountryIsdCode(values.countryObject.phone)
        setShowOTPInput(sendOTPResponse?.data?.requestProcessed)
        if (sendOTPResponse?.data?.requestProcessed) {
          setSeconds(60);
        }
      } catch (error: any) {
        setOpenAlert(true);
        setAlertMessage(`${error?.response?.data?.apierror?.message}! Please enter your registered phone no`);
        setAlertType('error');
      }
      setLoading1(false)
      // }, 1000)
    },
  })

  const formik2 = useFormik<UserModel>({
    initialValues: otpInitialData,
    validationSchema: otpFormValidationSchema,
    onSubmit: async (values) => {
      setLoading2(true)
      const body = {
        verificationRequestId: verificationRequestId,
        target: `${countryIsdCode}${phoneNumber}`,
        type: "SMS",
        purpose: "PHONE_VERIFICATION",
        verificationCode: values.otp
      }
      try {
        const response = await verifyOTP("auth/verify/otp", body)
        if (response.data.verified) {    
          setOpenAlert(true);
          setAlertMessage(`Phone no has been verified.`);
          setAlertType('success');      
          setIsPhoneVerified(response.data.verified);          
          onClose(response.data.verified);
        }
        else {
          setOpenAlert(true);
          setAlertMessage('Please enter valid otp.');
          setAlertType('error');
        }
      } catch (error: any) {
        setOpenAlert(true);
        setAlertMessage(error?.response?.data?.apierror?.message);
        setAlertType('error');
      }
      setLoading2(false)
      // }, 1000)
    },
  })

  const formik3 = useFormik<UserModel>({
    initialValues: otpInitialData,
    validationSchema: otpFormValidationSchema,
    onSubmit: async (values) => {
      setLoading3(true)
      // setTimeout(async (values) => {
      // setEmailUpdateData(values)
      const body = {
        id: id,
        target: phoneNo,
        type: "SMS",
        purpose: "PHONE_VERIFICATION",
        verificationCode: values.otp
      }
      try {
        const response = await verifyOTP("auth/verify/otp", body)
        if (response.data.verified) {
          setOpenAlert(true);
          setAlertMessage(`Phone no has been verified.`);
          setAlertType('success'); 
          setIsPhoneVerified(response.data.verified);
          navigate('/auth/sign-in')
          onClose(response.data.verified);
        }

      }
      catch (error: any) {
        setOpenAlert(true);
        setAlertMessage(error?.response?.data?.apierror?.message);
        setAlertType('error');
      }
      setLoading3(false)
      // }, 1000)
    },
  })

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth='sm'
        keepMounted
      >
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center' }}>
          <img alt='Logo' className='h-50px logo' src={toAbsoluteUrl('/media/logos/Til_logo.jpg')} />
          <h1>{'TIL Phone Verification'}</h1>
          <IconButton onClick={() => onClose(isPhoneVerified)}><CloseSharpIcon /></IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {signIn ?
            <>
              {!showOTPInput ?
                <form
                  onSubmit={formik1.handleSubmit}
                  id='kt_signin_change_email'
                  className='form'
                  noValidate
                >
                  <div className='row mb-6'>
                    <div className='col-lg-2'>
                      <Autocomplete
                        disablePortal
                        id="Exchange"
                        options={countryCodeList}
                        getOptionLabel={(option) => option.phone}
                        size='medium'
                        clearIcon={false}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ '& > img': { mr: 1, flexShrink: 0, fontSize: 6 } }} {...props}>
                            <img
                              loading="lazy"
                              width="15"
                              src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 1x`}
                              alt=""
                            />
                            <span className='fs-9'> {option.phone}-{option.label}</span>
                          </Box>

                        )}
                        value={formik1.values.countryObject}
                        onChange={(e, value) => { formik1.setFieldValue('countryObject', value) }}
                        renderInput={(params) => (<TextField {...params} label="Code" placeholder='select...'
                          error={
                            formik1.touched.countryObject &&
                            Boolean(formik1.errors.countryObject?.countryCode)
                          }
                          helperText={
                            formik1.touched.countryObject &&
                            formik1.errors.countryObject?.countryCode
                          }
                        />)}
                      />
                      {formik1.touched.countryObject && formik1.errors.countryObject?.countryCode && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik1.errors.countryObject.countryCode}</div>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-10'>
                      <TextField
                        type='text'
                        id='emailaddress'
                        label='Phone No'
                        placeholder='Enter phone no.'
                        {...formik1.getFieldProps('phoneNumber')}
                        inputProps={{
                          maxLength: 10
                        }}
                        InputLabelProps={{
                          className: 'form-label required fs-6 fw-bolder'
                        }}
                      />
                      {formik1.touched.phoneNumber && formik1.errors.phoneNumber && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik1.errors.phoneNumber}</div>
                        </div>
                      )}
                      <br />
                      {/* <span>OTP sent on {userData.email}</span> */}
                    </div>
                    <br />
                    <span className='danger fs-8 mt-2'>(Please enter your registered phone number with ISD code)</span>
                  </div>
                  <div className='d-flex'>
                    <button
                      id='kt_signin_submit'
                      type='submit'
                      className='btn btn-primary  me-2 px-6'
                    >
                      {!loading1 && 'Send OTP'}
                      {loading1 && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </form> :
                <form
                  onSubmit={formik2.handleSubmit}
                  id='kt_signin_change_email'
                  className='form'
                  noValidate
                >
                  <div className='row mb-6'>
                    <div className='col-lg-12 mb-4 mb-lg-0'>
                      <div className='fv-row mb-0'>
                        <TextField
                          type='text'
                          label='OTP'
                          id='emailaddress'
                          placeholder='Please enter otp'
                          {...formik2.getFieldProps('otp')}
                          InputLabelProps={{
                            className: 'form-label required fs-6 fw-bolder'
                          }}
                          inputProps={{
                            maxLength: 6
                          }}
                        />
                        {formik2.touched.otp && formik2.errors.otp && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik2.errors.otp}</div>
                          </div>
                        )}
                        <br />
                      </div>
                      <div className='fv-row mb-0'>
                        <div className='d-flex countdown-text fs-8'>
                          {seconds > 0 ? (
                            <p>
                              Time Remaining : 00:{seconds < 10 ? `0${seconds}` : seconds}
                            </p>
                          ) : (
                            <p>Didn't recieve code?</p>
                          )}
                          <span
                            style={{
                              marginLeft: 5,
                              cursor: 'pointer',
                              color: seconds > 0 ? "#392f28" : "#0095e8",
                              textDecorationLine: 'underline',
                              pointerEvents: seconds > 0 ? 'none' : 'auto'

                            }}
                            onClick={handleResendOTP}
                          >
                            Resend OTP
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex'>
                    <Button
                      id='kt_signin_submit'
                      type='submit'
                      variant='contained'
                      color='info'
                    // className='btn btn-primary  me-2 px-6'
                    >
                      {!loading2 && 'Verify Phone Number'}
                      {loading2 && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </Button>
                  </div>
                </form>}
            </> :
            <form
              onSubmit={formik3.handleSubmit}
              id='kt_signin_change_email'
              className='form'
              noValidate
            >
              <div className='row mb-6'>
                <div className='col-lg-6 mb-4 mb-lg-0'>
                  <div className='fv-row mb-2'>
                    <TextField
                      type='text'
                      label='Phone No'
                      className='form-control form-control-lg form-control-solid'
                      id='emailaddress'
                      placeholder='Enter phone no.'
                      disabled
                      value={phoneNo}
                      InputLabelProps={{
                        className: 'form-label fs-6 fw-bolder'
                      }}
                      inputProps={{
                        maxLength: 10
                      }}
                    />
                    <br />
                  </div>
                </div>
                <div className='col-lg-6 mb-4 mb-lg-0'>
                  <div className='fv-row mb-0'>
                    <TextField
                      type='text'
                      label='OTP'
                      className='form-control form-control-lg form-control-solid'
                      id='emailaddress'
                      placeholder='Please enter otp'
                      {...formik3.getFieldProps('otp')}
                      InputLabelProps={{
                        className: 'form-label required fs-6 fw-bolder'
                      }}
                      inputProps={{
                        maxLength: 6
                      }}
                    />
                    {formik3.touched.otp && formik3.errors.otp && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik3.errors.otp}</div>
                      </div>
                    )}
                    <br />
                    {/* <span>OTP sent on {userData.email}</span> */}
                  </div>
                  <div className='fv-row mb-0'>

                  </div>
                </div>

              </div>
              <div className='row mb-4'>
                <div className='col-lg-6 col-sm-12 mb-2'>
                  <Button
                    id='kt_signin_submit'
                    type='submit'
                    variant='contained'
                    color='info'
                  // className='btn btn-primary  me-2 px-6'
                  >
                    {!loading3 && 'Verify Phone Number'}
                    {loading3 && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </Button>
                </div>
                <div className='col-lg-6 col-sm-12 d-flex justify-content-end fs-8'>
                  {seconds > 0 ? (
                    <p>
                      Time Remaining : 00:{seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <p>Didn't recieve code?</p>
                  )}
                  <span
                    style={{
                      marginLeft: 5,
                      cursor: 'pointer',
                      color: seconds > 0 ? "#392f28" : "#0095e8",
                      textDecorationLine: 'underline',
                      pointerEvents: seconds > 0 ? 'none' : 'auto'

                    }}
                    onClick={handleResendOTP}
                  >
                    Resend OTP
                  </span>
                </div>
              </div>
            </form>
          }
        </DialogContent>
      </Dialog>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleSnackbarClose} severity={alertType == 'success' ? 'success' : 'error'}>{alertMessage}</Alert>
      </Snackbar>
    </>
  )
}
