/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { Dropdown1 } from '../../../../_metronic/partials/content/dropdown/Dropdown1'
import { getUserActivity } from '../../../modules/reducers/userActivitySlice'
import { useAppDispatch, useAppSelector } from '../../../../Store'
import { useAuth } from '../../../modules/auth'
import { Data } from '../../../modules/interfaces/UserActivity'
import moment from 'moment';
import AllUserActivityDialog from '../../../modules/commonDialog/AllUserActivityDialog'


type Props = {
    className: string
}

const UserActivity: React.FC<Props> = ({ className }) => {
    const dispatch = useAppDispatch();
    const token = useAuth().auth?.token;
    const [isOpenAllUserActivityDialog, setIsOpenAllUserActivityDialog] = useState<boolean | undefined>(false);
    const { userActivityData, loading } = useAppSelector((state) => state.userActivity);
    const [userData, setUserData] = useState<Data[]>();

    const handleDialog = () => {
        setIsOpenAllUserActivityDialog(false);
    }

    const body = {
        token: token,
    }

    useEffect(() => {
        dispatch(getUserActivity(body))
    }, [dispatch]);

    useEffect(() => {
        setUserData(userActivityData?.modal?.data.slice(0, 25))
    }, [userActivityData]);


    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header align-items-center border-0 mt-4'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bold mb-2 text-dark'>User Activities</span>
                    <span className='text-muted fw-semibold fs-7'>Top {userData?.length ? userData?.length : 0} User Activities</span>
                </h3>
                <Link to='' onClick={() => setIsOpenAllUserActivityDialog(true)} className='ml-58 pl-auto mt-8 mr-12'>
                    View All
                </Link>
                {/* <div className='card-toolbar'>
                    {/* begin::Menu */}
                    {/* <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                    </button>
                    <Dropdown1 /> */}
                    {/* end::Menu 
                </div> */}
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-5' style={{ maxHeight: '500px', overflowY: 'auto' }}>
                {/* begin::Timeline */}
                {userData && userData.length > 0 ? (
                    <div className='timeline-label'>
                        {userData.map((item, index) => (
                            <div className='timeline-item' key={index}>
                                <div className='timeline-label fw-bold text-gray-800 small' style={{fontSize:'8px'}}>
                                    {/* {moment(item.dateTime).format('YYYY-MM-DD HH:mm')} */}
                                    {moment(item.dateTime).format('DDMMMYY HH:mm')}                                    
                                </div>
                                <div className='timeline-badge'>
                                    <i className='fa fa-genderless text-warning fs-3'></i>
                                </div>
                                <div className='timeline-content fw-mormal text-muted ps-3 fs-8'>
                                    {item.messages}
                                </div>
                            </div>
                        ))}
                    </div>
                ) :
                    (
                        <div className='text-center text-muted fw-bold fs-3 mt-12'>No User Activity Performed</div>
                    )}
                {/* end::Timeline */}
            </div>
            {/* end: Card Body */}
            {isOpenAllUserActivityDialog && <AllUserActivityDialog open={isOpenAllUserActivityDialog} dialogClose={handleDialog} className='' />}

        </div>
    )
}

export { UserActivity }
