import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {DiscountCoupon} from '../interfaces/DiscountCoupon'
import {getDiscountCouponDetail} from '../http'

interface DiscountCouponState {  
  getDiscountDetailData: DiscountCoupon | null
  getDiscountDetailLoading: boolean
  getDiscountDetailErrors: any
}

const initialState: DiscountCouponState = {  
  getDiscountDetailData: null,
  getDiscountDetailLoading: false,
  getDiscountDetailErrors: null
}

export const getDiscountCouponDetailData = createAsyncThunk(
  'discountCouponDetail/getDiscountCouponDetailData',
  async ({token, id}: {token: any; id: any}, thunkAPI) => {
    try {      
      const response = await getDiscountCouponDetail(token, `discount-coupon-master/${id}`)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const discountCouponDetailSlice = createSlice({
  name: 'discountCouponDetail',
  initialState,
  reducers: {
    setDiscountCouponDetail: (state, action: PayloadAction<DiscountCoupon>) => {
      state.getDiscountDetailData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDiscountCouponDetailData.pending, (state, action) => {
      state.getDiscountDetailLoading = false
      state.getDiscountDetailData = null
    })
    builder.addCase(getDiscountCouponDetailData.fulfilled, (state, action) => {
      state.getDiscountDetailLoading = true
      state.getDiscountDetailData = action.payload
      state.getDiscountDetailErrors = null
    })
    builder.addCase(getDiscountCouponDetailData.rejected, (state, action) => {
      state.getDiscountDetailLoading = false
      state.getDiscountDetailErrors = action.payload
      state.getDiscountDetailData = null
    })    
  },
})

export default discountCouponDetailSlice.reducer
export const {setDiscountCouponDetail} = discountCouponDetailSlice.actions
