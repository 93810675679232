import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IndicatorStrategyMapping} from '../interfaces/IndicatorStrategyMapping'
import {
  getAllIndicatorStrategyMapping, 
  createIndicatorStrategyMapping as postIndicatorStrategyMapping, 
  editIndicatorStrategyMapping as putIndicatorStrategyMapping
} from '../http'

interface IndicatorStrategyMappingState {
  getIndicatorStrategyMappingData: IndicatorStrategyMapping | null
  getLoading: boolean
  getErrors: any
  createIndicatorStrategyMappingData: IndicatorStrategyMapping | null
  createLoading: boolean
  createErrors: any
  editIndicatorStrategyMappingData: IndicatorStrategyMapping | null
  editLoading: boolean
  editErrors: any
}

const initialState: IndicatorStrategyMappingState = {
  getIndicatorStrategyMappingData: null,
  getLoading: false,
  getErrors: null,
  createIndicatorStrategyMappingData: null,
  createLoading: false,
  createErrors: null,
  editIndicatorStrategyMappingData: null,
  editLoading: false,
  editErrors: null,
}

export const getIndicatorStrategyMapping = createAsyncThunk(
  'indicatorStrategyMapping/getIndicatorStrategyMapping',
  async ({token}: {token: any;}, thunkAPI) => {
    try {     
      const response = await getAllIndicatorStrategyMapping(token, 'strategy-indicator-mapping/all')
      return response.data
    } catch (error) {      
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const createIndicatorStrategyMapping = createAsyncThunk(
  'indicatorStrategyMapping/createIndicatorStrategyMapping',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data      
      const response = await postIndicatorStrategyMapping(token, 'strategy-indicator-mapping/', body)
      return response.data
    } catch (error) {     
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const editIndicatorStrategyMapping = createAsyncThunk(
  'indicatorStrategyMapping/editIndicatorStrategyMapping',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data      
      const response = await putIndicatorStrategyMapping(token, `strategy-indicator-mapping/${body.id}`, body)
      return response.data
    } catch (error) {      
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const indicatorStrategyMappingSlice = createSlice({
  name: 'getIndicatorStrategyMapping',
  initialState,
  reducers: {
    setIndicatorStrategyMapping: (state, action: PayloadAction<IndicatorStrategyMapping>) => {
      state.getIndicatorStrategyMappingData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIndicatorStrategyMapping.pending, (state, action) => {
      state.getLoading = true
      state.getIndicatorStrategyMappingData = null
    })
    builder.addCase(getIndicatorStrategyMapping.fulfilled, (state, action) => {
      state.getLoading = false
      state.getIndicatorStrategyMappingData = action.payload
      state.getErrors = null
    })
    builder.addCase(getIndicatorStrategyMapping.rejected, (state, action) => {
      state.getLoading = false
      state.getErrors = action.payload
      state.getIndicatorStrategyMappingData = null
    })
    builder.addCase(createIndicatorStrategyMapping.pending, (state, action) => {
      state.createLoading = true
      state.createIndicatorStrategyMappingData = null
    })
    builder.addCase(createIndicatorStrategyMapping.fulfilled, (state, action) => {
      state.createLoading = false
      state.createIndicatorStrategyMappingData = action.payload
      state.createErrors = null
    })
    builder.addCase(createIndicatorStrategyMapping.rejected, (state, action) => {
      state.createLoading = false
      state.createErrors = action.payload
      state.createIndicatorStrategyMappingData = null
      console.log(state.createErrors)
      console.log(action.payload)
    })
    builder.addCase(editIndicatorStrategyMapping.pending, (state, action) => {
      state.editLoading = true
      state.editIndicatorStrategyMappingData = null
    })
    builder.addCase(editIndicatorStrategyMapping.fulfilled, (state, action) => {
      state.editLoading = false
      state.editIndicatorStrategyMappingData = action.payload
      state.editErrors = null
    })
    builder.addCase(editIndicatorStrategyMapping.rejected, (state, action) => {
      state.editLoading = false
      state.editErrors = action.payload
      state.editIndicatorStrategyMappingData = null
    })
  },
})

export default indicatorStrategyMappingSlice.reducer
export const {setIndicatorStrategyMapping} = indicatorStrategyMappingSlice.actions
