import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const MixedWidget11: React.FC<Props> = ({ className, chartColor, chartHeight }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const [activeTab, setActiveTab] = useState('day') // Default active tab is 'day'
  const [dateRange, setDateRange] = useState<string>('')

  useEffect(() => {
    // Update date range based on active tab
    if (activeTab === 'day') {
      const today = new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
      setDateRange(today);
    } else if (activeTab === 'week') {
      const today = new Date();
      const lastWeekStart = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
      const lastWeekStartDate = lastWeekStart.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
      const todayDate = today.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
      setDateRange(`${lastWeekStartDate} - ${todayDate}`);
    } else if (activeTab === 'Month') {
      const today = new Date();
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
      const lastMonthStartDate = lastMonth.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
      const todayDate = today.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
      setDateRange(`${lastMonthStartDate} - ${todayDate}`);
    }
  }, [activeTab]);

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  const handleTabClick = (tab: string) => {
    setActiveTab(tab)
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Toolbar */}
      <div className='card-toolbar mt-5 ms-5'>
        <ul className='nav'>
          <li className='nav-item'>
            <a
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${activeTab === 'Month' ? 'active' : ''}`}
              data-bs-toggle='tab'
              href='#'
              onClick={() => handleTabClick('Month')}
            >
              Month
            </a>
          </li>
          <li className='nav-item'>
            <a
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 ${activeTab === 'week' ? 'active' : ''}`}
              data-bs-toggle='tab'
              href='#'
              onClick={() => handleTabClick('week')}
            >
              Week
            </a>
          </li>
          <li className='nav-item'>
            <a
              className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${activeTab === 'day' ? 'active' : ''}`}
              data-bs-toggle='tab'
              href='#'
              onClick={() => handleTabClick('day')}
            >
              Day
            </a>
          </li>
        </ul>
      </div>
      {/* end::Toolbar */}

      {/* begin::Body */}
      <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
        {/* begin::Hidden */}
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bold text-gray-800 d-block fs-3'>Profit and Loss</span>
            <span className='text-gray-400 fw-semibold'>{dateRange}</span>
          </div>
          <div className={`fw-bold fs-3 text-${chartColor}`}>$15,300</div>
        </div>
        {/* end::Hidden */}

        {/* begin::Chart */}
        <div ref={chartRef} className='mixed-widget-10-chart'></div>
        {/* end::Chart */}
      </div>
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')
  const baseColor = getCSSVariableValue('--kt-' + chartColor)

  return {
    series: [
      {
        name: 'Net Profit',
        data: [50, 60, 70, 80, 60, 50, 70, 60],
      },
      {
        name: 'Revenue',
        data: [50, 60, 70, 80, 60, 50, 70, 60],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    fill: {
      type: 'solid',
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return '$' + val + ' revenue'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export { MixedWidget11 }
