import axios from 'axios'
import { Data as strategyData } from '../interfaces/Strategy'
import { Data as brokerData } from '../interfaces/ClientExchange'
import { Data as userData } from '../interfaces/User'
import { Data as exchangeData } from '../interfaces/ClientExchange'
import { Data as referralData } from '../interfaces/Referral'
import { Data as rewardPointCategoryData } from '../interfaces/RewardPointCategory'
import { Data as rewardPointPlanData } from '../interfaces/RewardPointPlan'
import { Data as rewardPointValueData } from '../interfaces/RewardPointValue'
import { Data as subscriptionPlanData } from '../interfaces/SubscriptionPlan'
import { Data as discountCouponData } from '../interfaces/DiscountCoupon'
import { Indicies } from '../interfaces/Indicies'
import { Indicator } from '../interfaces/indicator'
import { IndicatorStrategyMapping } from '../interfaces/IndicatorStrategyMapping'

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

// Calling Api For User //
// Start //

export function getUserProfile(api: string, token: string) {
  const URL = BASE_URL + api
  return axios.get<userData>(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getUserAllForAdmin(api: string, token: string) {
  const URL = BASE_URL + api
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getUserAll(api: string, token: string) {
  const URL = BASE_URL + api
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function createUser(api: string, body: userData) {
  const createUserURL = BASE_URL + api

  return axios.post(createUserURL, body)
}

export function updateUserProfile(api: string, token: string, body: userData) {
  const URL = BASE_URL + api
  return axios.put(URL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function signOut(api: string, token: string, body: any) {
  const URL = BASE_URL + api
  return axios.post(URL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function changePassword(token: string, api: string, body: any) {
  const URL = BASE_URL + api
  return axios.put(URL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function uploadProfileImage(token: string, api: string, body: any) {
  const URL = BASE_URL + api
  return axios.post(URL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
      // 'Content-Type': 'multipart/form-data'
    },
  })
}

export function resetPassword(api: string, body: any) {
  const URL = BASE_URL + api
  return axios.put(URL, body)
}

// End //

// Calling Api For Strategy //
// Start //

export function getAllStrategy(token: string, api: string) {
  const strategyURL = BASE_URL + api

  return axios.get(strategyURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function createStrategy(token: any, api: string, body: strategyData) {
  const strategyURL = BASE_URL + api

  return axios.post(strategyURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

//Edit Strategy
export function editStrategy(token: string, api: string, body: strategyData) {
  const strategyURL = BASE_URL + api;

  return axios.put(strategyURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

//Delete Strategy
export function deleteStrategy(token: any, api: string, data: any) {
  const strategyURL = BASE_URL + api;

  return axios.patch(strategyURL, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function subscribedStrategy(token: string, api: string, body: strategyData) {
  const strategyURL = BASE_URL + api

  return axios.post(strategyURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function unSubscribedStrategy(token: string, api: string) {
  const strategyURL = BASE_URL + api

  return axios.get(strategyURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Calling Api For Client Exchange/Broker //
// Start //

export function getAllClientExchangeByUser(token: string, api: string) {
  const clientExchangeURL = BASE_URL + api

  return axios.get(clientExchangeURL, {
    headers: {
      Authorization: token,
    },
  })
}

export function getClientExchangeByUser(token: string, api: string) {
  const clientExchangeURL = BASE_URL + api

  return axios.get(clientExchangeURL, {
    headers: {
      Authorization: token,
    },
  })
}

export function createClientExchange(token: string, api: string, body: brokerData) {
  const clientExchangeURL = BASE_URL + api
  return axios.post(clientExchangeURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function editClientExchange(token: string, api: string, body: brokerData) {
  const clientExchangeURL = BASE_URL + api

  return axios.put(clientExchangeURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function updateClientExhangeAccessToken(token: any, api: string, body: any) {
  const clientExchangeURL = BASE_URL + api

  return axios.post(clientExchangeURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function updateClientExhangeAccessTokenDirect(token: any, api: string, body: any) {
  const clientExchangeURL = BASE_URL + api

  return axios.patch(clientExchangeURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Calling Api For Subscribe //
// Start //

export function getAllSubscriptionByUser(token: string, api: string) {
  const subscriptionURL = BASE_URL + api

  return axios.get(subscriptionURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getZerodhaToken(token: any, api: any) {
  const tokenURL = BASE_URL + api

  return axios.get(tokenURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function createSubscription(token: string, api: string, body: strategyData) {
  const strategyURL = BASE_URL + api

  return axios.post(strategyURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Calling Api For Go Live Strategy //
// Start //

export function getAllLiveStrategyByUser(token: string, api: string) {
  const URL = BASE_URL + api

  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function updateGoLiveStrategy(token: any, api: string, body: any) {
  const URL = BASE_URL + api

  return axios.put(URL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Calling Api For Algo Signal Processing //
// Start //

export function getAllAlgoSignalProcessing(token: string, api: string) {
  const URL = BASE_URL + api

  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getAllAlgoSignalProcessingDetail(token: string, api: string) {
  const URL = BASE_URL + api

  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function reProcessAlgoSignal(token: any, api: string) {
  const URL = BASE_URL + api
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function reConciliationAlgoSignal(token: any, api: string) {
  const URL = BASE_URL + api
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function processManually(token: any, api: string, data: any) {
  const URL = BASE_URL + api
  return axios.patch(URL, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function cancelAlgoSignalForStats(token: any, api: string, data: any) {
  const URL = BASE_URL + api
  return axios.patch(URL, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function reConciliationForStats(token: any, api: string) {
  const URL = BASE_URL + api
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getDashboardExchange(token: any, api: string) {
  const URL = BASE_URL + api
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getStatsHoldings(token: any, api: string) {
  const URL = BASE_URL + api
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}



// End //

// Calling Api For Broker/Exchange //
// Start //

export function getAllExchange(token: string, api: string) {
  const exchangeURL = BASE_URL + api

  return axios.get(exchangeURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function createExchange(token: string, api: string, body: exchangeData) {
  const exchangeURL = BASE_URL + api

  return axios.post(exchangeURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function editExchange(token: string, api: string, body: exchangeData) {
  const exchangeURL = BASE_URL + api

  return axios.put(exchangeURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Create Payment Order //
// Start //

export function createPaymentOrder(token: string, api: string, body: strategyData) {
  const createPaymentOrderURL = BASE_URL + api

  return axios.post(createPaymentOrderURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function paymentOrderCallback(token: string, api: string, body: strategyData) {
  const paymentOrderCallbackURL = BASE_URL + api

  return axios.post(paymentOrderCallbackURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Referral //
// Start //

export function sendReferralCode(token: string, api: string, body: referralData) {
  const sendReferralCodeURL = BASE_URL + api

  return axios.post(sendReferralCodeURL, body)
}

//Get
export function getReferralGraphData(token: string, api: string) {
  const referralGraphURL = BASE_URL + api

  return axios.get(referralGraphURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// Send OTP //
export function sendOTP(api: string, body: any) {
  const sendOTPURL = BASE_URL + api

  return axios.post(sendOTPURL, body)
}

// Verify OTP //
export function verifyOTP(api: string, body: any) {
  const verifyOTPURL = BASE_URL + api

  return axios.post(verifyOTPURL, body)
}

// Send OTP with Token //
export function sendOTPWithToken(token: string, api: string, body: any) {
  const sendOTPURL = BASE_URL + api

  return axios.post(sendOTPURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// Verify OTP with Token //
export function verifyOTPWithToken(token: string, api: string, body: any) {
  const verifyOTPURL = BASE_URL + api

  return axios.post(verifyOTPURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// Calling Api For Admin Reward Point Category //
// Start //

export function getAllRewardPointCategory(token: string, api: string) {
  const rewardPointCategoryURL = BASE_URL + api

  return axios.get(rewardPointCategoryURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function createRewardPointCategory(token: string, api: string, body: rewardPointCategoryData) {
  const rewardPointCategoryURL = BASE_URL + api

  return axios.post(rewardPointCategoryURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function editRewardPointCategory(token: string, api: string, body: rewardPointCategoryData) {
  const rewardPointCategoryURL = BASE_URL + api

  return axios.put(rewardPointCategoryURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Calling Api For Admin Reward Point Plan //
// Start //

export function getAllRewardPointPlan(token: string, api: string) {
  const rewardPointPlanURL = BASE_URL + api

  return axios.get(rewardPointPlanURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function createRewardPointPlan(token: string, api: string, body: rewardPointPlanData) {
  const rewardPointPlanURL = BASE_URL + api

  return axios.post(rewardPointPlanURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function editRewardPointPlan(token: string, api: string, body: rewardPointPlanData) {
  const rewardPointPlanURL = BASE_URL + api

  return axios.put(rewardPointPlanURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Calling Api For Admin Reward Point Plan //
// Start //

export function getAllRewardPointValues(token: string, api: string) {
  const rewardPointValueURL = BASE_URL + api

  return axios.get(rewardPointValueURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function createRewardPointValue(token: string, api: string, body: rewardPointValueData) {
  const rewardPointValueURL = BASE_URL + api

  return axios.post(rewardPointValueURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function editRewardPointValue(token: string, api: string, body: rewardPointValueData) {
  const rewardPointValueURL = BASE_URL + api

  return axios.put(rewardPointValueURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Calling Api For Multi User Exchange //
// Start //

export function getMultiUserExchange(token: string, api: string, body: any) {
  const multiUserExchangeURL = BASE_URL + api

  return axios.post(multiUserExchangeURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Calling Api For Multi User Exchange //
// Start //

export function getAllInstrument(token: string, api: string) {
  const instrumentAllURL = BASE_URL + api

  return axios.get(instrumentAllURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getAllInstrumentByFilter(token: string, api: string, body: any) {
  const instrumentAllURL = BASE_URL + api

  return axios.post(instrumentAllURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Calling Api For Admin Subscription Plan //
// Start //

export function getAllSubscriptionPlan(token: string, api: string) {
  const subscriptionPlanURL = BASE_URL + api

  return axios.get(subscriptionPlanURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function createSubscriptionPlan(token: string, api: string, body: subscriptionPlanData) {
  const subscriptionPlanURL = BASE_URL + api

  return axios.post(subscriptionPlanURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function editSubscriptionPlan(token: string, api: string, body: subscriptionPlanData) {
  const subscriptionPlanURL = BASE_URL + api

  return axios.put(subscriptionPlanURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Calling Api For Admin Discount Coupon //
// Start //

export function getAllLazyDiscountCoupon(token: string, api: string) {
  const discountCouponURL = BASE_URL + api

  return axios.get(discountCouponURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getAlgoActivityData(token: string, api: string) {
  const algoActivityURL = BASE_URL + api

  return axios.get(algoActivityURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getUserActivityData(token: string, api: string) {
  const userActivityURL = BASE_URL + api

  return axios.get(userActivityURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getDiscountCouponDetail(token: string, api: string) {
  const discountCouponDetailURL = BASE_URL + api

  return axios.get(discountCouponDetailURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function createDiscountCoupon(token: string, api: string, body: discountCouponData) {
  const discountCouponURL = BASE_URL + api

  return axios.post(discountCouponURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function editDiscountCoupon(token: string, api: string, body: discountCouponData) {
  const discountCouponURL = BASE_URL + api

  return axios.put(discountCouponURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function isValidDiscountCoupon(token: string, api: string) {
  const isValidDiscountCouponURL = BASE_URL + api

  return axios.post(isValidDiscountCouponURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Start Buy/Sell Order //

export function placeBuySellOrder(token: string, api: string, body: any) {
  const buySellOrderURL = BASE_URL + api

  return axios.post(buySellOrderURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Start Get Stock Ananlysis Record //

export function getStockAnalysisData(token: any, api: string, body: any) {
  const stockAnalysisURL = BASE_URL + api

  return axios.post(stockAnalysisURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getStockHistoryData(token: any, api: string, body: any) {
  const stockHistoryURL = BASE_URL + api

  return axios.post(stockHistoryURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Calling Api For Indicies //
// Start //

export function getAllIndicies(token: string, api: string) {
  const indiciesURL = BASE_URL + api

  return axios.get(indiciesURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getIndiciesByName(token: any, api: string) {
  const indiciesURL = BASE_URL + api

  return axios.get(indiciesURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function createIndicies(token: string, api: string, body: Indicies) {
  const indiciesURL = BASE_URL + api

  return axios.post(indiciesURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function editIndicies(token: string, api: string, body: Indicies) {
  const indiciesURL = BASE_URL + api

  return axios.put(indiciesURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Start Cross Reference //

export function createCrossReference(token: string, api: string, body: any) {
  const crossReferenceURL = BASE_URL + api

  return axios.post(crossReferenceURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// Algo Sync //

export function getSyncAlgoByUser(token: any, api: string) {
  const syncApiURL = BASE_URL + api

  return axios.get(syncApiURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function syncAlgoByUser(token: string, api: string, body: any) {
  const syncApiURL = BASE_URL + api

  return axios.post(syncApiURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Calling Api For Indicator Master //
// Start //

export function getAllIndicator(token: string, api: string) {
  const indicatorURL = BASE_URL + api

  return axios.get(indicatorURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function createIndicator(token: string, api: string, body: Indicator) {
  const indicatorURL = BASE_URL + api

  return axios.post(indicatorURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function editIndicator(token: string, api: string, body: Indicator) {
  const indicatorURL = BASE_URL + api

  return axios.put(indicatorURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Calling Api For Indicator Strategy Mapping Master //
// Start //

export function getAllIndicatorStrategyMapping(token: string, api: string) {
  const indicatorStrategyMappingURL = BASE_URL + api

  return axios.get(indicatorStrategyMappingURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function createIndicatorStrategyMapping(token: string, api: string, body: IndicatorStrategyMapping) {
  const indicatorStrategyMappingURL = BASE_URL + api

  return axios.post(indicatorStrategyMappingURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function editIndicatorStrategyMapping(token: string, api: string, body: IndicatorStrategyMapping) {
  const indicatorStrategyMappingURL = BASE_URL + api

  return axios.put(indicatorStrategyMappingURL, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Start Delete Item //
export function deleteItem(token: string, api: string) {
  const deleteItemURL = BASE_URL + api

  return axios.delete(deleteItemURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// End //

// Calling Api For Indicator Strategy Mapping Master //
// Start //

export function getAllStrategyIndicatorConfigIndicator(token: string, api: string) {
  const strategyIndicatorConfigIndicatorURL = BASE_URL + api

  return axios.get(strategyIndicatorConfigIndicatorURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// END //