import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AlgoSignalProcessMaster } from '../interfaces/AlgoSignalProcessMaster'
import { AlgoSignalProcessingDetail } from '../interfaces/AlgoSignalProcessingDetail'
import { getAllAlgoSignalProcessing, getAllAlgoSignalProcessingDetail } from '../http'

interface AlgoSignalProcessingState {
  algoSignalProcessMaster: AlgoSignalProcessMaster | null
  loading: boolean
  errors: any
  algoSignalProcessDetail: AlgoSignalProcessMaster | null
  detailLoading: boolean
  detailErrors: any
  algoSignalPaperTradingData: AlgoSignalProcessMaster | null
  paperTradingLoading: boolean
  paperTradingerrors: any
  algoSignalProcessMasterHistory: AlgoSignalProcessMaster | null
  historyLoading: boolean
  historyErrors: any
}

const initialState: AlgoSignalProcessingState = {
  algoSignalProcessMaster: null,
  loading: false,
  errors: null,
  algoSignalProcessDetail: null,
  detailLoading: false,
  detailErrors: null,
  algoSignalPaperTradingData: null,
  paperTradingLoading: false,
  paperTradingerrors: null,
  algoSignalProcessMasterHistory: null,
  historyLoading: false,
  historyErrors: null,
}

export const getAlgoSignalProcessingData = createAsyncThunk(
  'algoSignalProcessing/getAlgoSignalProcessingData',
  async ({ token, fromDate, toDate, details, userId }: { token: any; fromDate: any; toDate: any; details: boolean, userId: any }, thunkAPI) => {
    try {
      const response = await getAllAlgoSignalProcessing(
        token,
        `transaction-master/bydate?fromDate=${fromDate}&toDate=${toDate}&details=${details}&userId=${userId}`
      )
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getPaperTradingData = createAsyncThunk(
  'algoSignalProcessing/getPaperTradingData',
  async ({ token, fromDate, toDate,strategyUniquekey, isFlatternInDetailDTO }: { token: any; fromDate: any; toDate: any;strategyUniquekey: any; isFlatternInDetailDTO: boolean }, thunkAPI) => {
    try {
      const response = await getAllAlgoSignalProcessing(
        token,
        `signal/by-date-range?fromDate=${fromDate}&toDate=${toDate}&strategyUniquekey=${strategyUniquekey}&isFlatternInDetailDTO=${isFlatternInDetailDTO}`
      )
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getAlgoSignalProcessingDetailData = createAsyncThunk(
  'algoSignalProcessing/getAlgoSignalProcessingDetailData',
  async ({ token, id }: { token: any; id: any; }, thunkAPI) => {
    try {
      console.log(id)
      const response = await getAllAlgoSignalProcessingDetail(
        token,
        `transaction-master/${id}`
      )
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getAlgoSignalProcessingHistoryData = createAsyncThunk(
  'algoSignalProcessing/getAlgoSignalProcessingHistoryData',
  async ({ token, fromDate, toDate, details}: { token: any; fromDate: any; toDate: any ;details?: boolean; }, thunkAPI) => {
    try {
      const response = await getAllAlgoSignalProcessing(
        token,
        `transaction-master/all-users/bydate?fromDate=${fromDate}&toDate=${toDate}&details=${details}`
      )
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const algoSignalProcessingSlice = createSlice({
  name: 'algoSignalProcessingSlice',
  initialState,
  reducers: {
    setAlgoSignalProcessing: (state, action: PayloadAction<AlgoSignalProcessMaster>) => {
      state.algoSignalProcessMaster = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAlgoSignalProcessingData.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getAlgoSignalProcessingData.fulfilled, (state, action) => {
      state.loading = false
      state.algoSignalProcessMaster = action.payload
      state.errors = null
    })
    builder.addCase(getAlgoSignalProcessingData.rejected, (state, action) => {
      state.loading = false
      state.errors = action.payload
      state.algoSignalProcessMaster = null
    })
    builder.addCase(getAlgoSignalProcessingDetailData.pending, (state, action) => {
      state.detailLoading = true;
    });
    builder.addCase(getAlgoSignalProcessingDetailData.fulfilled, (state, action) => {
      state.detailLoading = false;
      state.algoSignalProcessDetail = action.payload;
      state.detailErrors = null
    });
    builder.addCase(getAlgoSignalProcessingDetailData.rejected, (state, action) => {
      state.detailLoading = false;
      state.detailErrors = action.payload;
      state.algoSignalProcessDetail = null
    });
    builder.addCase(getPaperTradingData.pending, (state, action) => {
      state.paperTradingLoading = true
    });
    builder.addCase(getPaperTradingData.fulfilled, (state, action) => {
      state.paperTradingLoading = false
      state.algoSignalPaperTradingData = action.payload
      state.paperTradingerrors = null
    });
    builder.addCase(getPaperTradingData.rejected, (state, action) => {
      state.paperTradingLoading = false
      state.paperTradingerrors = action.payload
      state.algoSignalPaperTradingData = null
    });
    builder.addCase(getAlgoSignalProcessingHistoryData.pending, (state, action) => {
      state.historyLoading = true
    })
    builder.addCase(getAlgoSignalProcessingHistoryData.fulfilled, (state, action) => {
      state.historyLoading = false
      state.algoSignalProcessMasterHistory = action.payload
      state.historyErrors = null
    })
    builder.addCase(getAlgoSignalProcessingHistoryData.rejected, (state, action) => {
      state.historyLoading = false
      state.historyErrors = action.payload
      state.algoSignalProcessMasterHistory = null
    })
  },
})

export default algoSignalProcessingSlice.reducer
export const { setAlgoSignalProcessing } = algoSignalProcessingSlice.actions
