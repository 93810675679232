import React from 'react'
import { MarketData } from "react-ts-tradingview-widgets";

const defaultSymbolGroup = [
  {
    name: "Indices",
    originalName: "Indices",
    symbols: [
      {
        name: "BSE:SENSEX",
        diaplayName: "SENSEX"
      },
      {
        name: "NSE:NIFTY",
        diaplayName: "NIFTY 50"
      },
      {
        name: "NSE:BANKNIFTY",
        diaplayName: "NIFTY BANK"
      },
      {
        name: "FOREXCOM:SPXUSD",
        displayName: "S&P 500",
      },
      {
        name: "FOREXCOM:NSXUSD",
        displayName: "Nasdaq 100",
      },
    ],
  },
  {
    name: "Commodities",
    originalName: "Commodities",
    symbols: [
      {
        name: "CME_MINI:ES1!",
        displayName: "S&P 500",
      },
      {
        name: "CME:6E1!",
        displayName: "Euro",
      },
      {
        name: "COMEX:GC1!",
        displayName: "Gold",
      },
      {
        name: "NYMEX:CL1!",
        displayName: "Crude Oil",
      },
      {
        name: "NYMEX:NG1!",
        displayName: "Natural Gas",
      },
      {
        name: "CBOT:ZC1!",
        displayName: "Corn",
      },
    ],
  },
  {
    name: "Bonds",
    originalName: "Bonds",
    symbols: [
      {
        name: "TVC:IN01",
        displayName: "IN01",
      },
      {
        name: "TVC:IN05",
        displayName: "IN05",
      },
      {
        name: "TVC:IN10",
        displayName: "IN10",
      },
      {
        name: "TVC:IN10Y",
        displayName: "IN10Y",
      },
      {
        name: "TVC:IN15",
        displayName: "IN15",
      },
      {
        name: "TVC:IN30",
        displayName: "IN30",
      },
    ],
  },
  {
    name: "Forex",
    originalName: "Forex",
    symbols: [
      {
        name: "FX_IDC:INRCAX",
      },
      {
        name: "FX_IDC:INRAUD",
      },
      {
        name: "FX_IDC:INRUSD",
      },
      {
        name: "FX_IDC:INREUR",
      },
      {
        name: "FX_IDC:INRGBP",
      },
      {
        name: "FX_IDC:INRJPY",
      },
    ],
  },
];

function MarketDataView() {
  return (
    <>
      <MarketData symbolsGroups={defaultSymbolGroup} colorTheme='light' locale='en' width='100%' />
    </>
  )
}

export default MarketDataView
