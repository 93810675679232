import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserLiveStrategy} from '../interfaces/UserLiveStrategy'
import {getAllLiveStrategyByUser} from '../http'

interface UserLiveStrategyState {
  userLiveStrategyForSubscription: UserLiveStrategy | null
  loadingForSubscription: boolean
  errorsForSubscription: any
  userLiveStrategyForLive: UserLiveStrategy | null
  loadingForLive: boolean
  errorsForLive: any
}

const initialState: UserLiveStrategyState = {
  userLiveStrategyForSubscription: null,
  loadingForSubscription: false,
  errorsForSubscription: null,
  userLiveStrategyForLive: null,
  loadingForLive: false,
  errorsForLive: null,
}

export const getUserLiveStrategyByUserForSubscription = createAsyncThunk(
  'userLiveStrategy/getUserLiveStrategyByUserForSubscription',
  async ({token, userId, isGoLive}: {token: any; userId: any; isGoLive: boolean}, thunkAPI) => {
    try {
      const response = await getAllLiveStrategyByUser(
        token,
        `client-strategy/golive/byuser?golive=${isGoLive}&userId=${userId}`
      )
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getUserLiveStrategyByUserForLive = createAsyncThunk(
  'userLiveStrategy/getUserLiveStrategyByUserForLive',
  async ({token, userId, isGoLive}: {token: any; userId: any; isGoLive: boolean}, thunkAPI) => {
    try {
      const response = await getAllLiveStrategyByUser(
        token,
        `client-strategy/golive/byuser?golive=${isGoLive}&userId=${userId}`
      )
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const userLiveStrategySlice = createSlice({
  name: 'getUserLiveStrategyByUserForSubscription',
  initialState,
  reducers: {
    setUserLiveStrategy: (state, action: PayloadAction<UserLiveStrategy>) => {
      state.userLiveStrategyForSubscription = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserLiveStrategyByUserForSubscription.pending, (state, action) => {
      state.loadingForSubscription = true
    })
    builder.addCase(getUserLiveStrategyByUserForSubscription.fulfilled, (state, action) => {
      state.loadingForSubscription = false
      state.userLiveStrategyForSubscription = action.payload
      state.errorsForSubscription = null
    })
    builder.addCase(getUserLiveStrategyByUserForSubscription.rejected, (state, action) => {
      state.loadingForSubscription = false
      state.errorsForSubscription = action.payload
      state.userLiveStrategyForSubscription = null
    })
    builder.addCase(getUserLiveStrategyByUserForLive.pending, (state, action) => {
      state.loadingForLive = true
    })
    builder.addCase(getUserLiveStrategyByUserForLive.fulfilled, (state, action) => {
      state.loadingForLive = false
      state.userLiveStrategyForLive = action.payload
      state.errorsForLive = null
    })
    builder.addCase(getUserLiveStrategyByUserForLive.rejected, (state, action) => {
      state.loadingForLive = false
      state.errorsForLive = action.payload
      state.userLiveStrategyForLive = null
    })
  },
})

export default userLiveStrategySlice.reducer
export const {setUserLiveStrategy} = userLiveStrategySlice.actions
