import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PaymentOrder} from '../interfaces/PaymentOrder'
import {createPaymentOrder as postPaymentOrder} from '../http'

interface PaymentOrderState {
  paymentOrder: PaymentOrder | null
  loading: boolean
  errors: any
}

const initialState: PaymentOrderState = {
  paymentOrder: null,
  loading: false,
  errors: null,
}

export const createPaymentOrder = createAsyncThunk(
  'paymentOrder/createPaymentOrder',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      const response = await postPaymentOrder(token, 'user-subscription', body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const paymentOrderSlice = createSlice({
  name: 'paymentOrder',
  initialState,
  reducers: {
    setPaymentOrder: (state, action: PayloadAction<PaymentOrder>) => {
      state.paymentOrder = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createPaymentOrder.pending, (state, action) => {
      state.loading = false
      state.paymentOrder = null
    })
    builder.addCase(createPaymentOrder.fulfilled, (state, action) => {
      state.loading = true
      state.paymentOrder = action.payload
      state.errors = null
    })
    builder.addCase(createPaymentOrder.rejected, (state, action) => {
      state.loading = true
      state.errors = action.payload
      state.paymentOrder = null
    })
  },
})

export default paymentOrderSlice.reducer
export const {setPaymentOrder} = paymentOrderSlice.actions
