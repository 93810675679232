import React from "react";
import { Chip, Slide, Tooltip } from "@mui/material";
import { is } from "date-fns/locale";
import { TransitionProps } from "@mui/material/transitions";

interface CustomChipProps {
    label: string;
    color?: "primary" | "secondary" | "success" | "error" | "warning" | "info";
    width?: number;
    height?: number;
    fontSize?: number;
    tooltipText?: string;
    isCustomColor?: boolean;
    CustomColor?: string;
    isFontWeight?: boolean;
}

export const CustomChip: React.FC<CustomChipProps> = ({
    label,
    color,
    width = 80,
    height = 24,
    fontSize = 10,
    tooltipText,
    isCustomColor = false,
    CustomColor,
    isFontWeight = false
}) => {
    return (
        <Tooltip 
            title={tooltipText ?? label}
            placement='right-start'
            sx={{
                fontWeight: 'bold'
            }}
            arrow
        >
            <Chip
                label={label.toUpperCase()}
                color={color}
                sx={{
                    fontSize,
                    width,
                    height,
                    backgroundColor: isCustomColor ? CustomColor : "",
                    color: isCustomColor ? "white" : "",
                    fontWeight: isFontWeight ? "bold" : null,
                    letterSpacing: "0.3px", // Adds spacing for clarity
                    textRendering: "optimizeLegibility", // Ensures sharp text
                    WebkitFontSmoothing: "auto", // Prevents text blurring
                    MozOsxFontSmoothing: "grayscale", // Fixes MacOS font smoothing
                    borderRadius: "8px",                    
                }}
                size="small"
            />
      </Tooltip>
    );
};

interface CustomTooltipProps {
    title: string;
    fontWeight?: string;
    placement?: "right-start" | "right-end" | "left-start" | "left-end" | "top-start" | "top-end" | "bottom-start" | "bottom-end";    
    children;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ 
    title,
    fontWeight,
    placement,
    children
 }) => {
    return (
        <Tooltip 
            title={title}
            placement={placement}
            sx={{
                fontWeight: fontWeight
            }}
            arrow
        >
            {children}
        </Tooltip>
    );
}

export const DialogTransition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="left" ref={ref} {...props} />;
});