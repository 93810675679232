import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ClientExchange} from '../interfaces/ClientExchange'
import {getAllExchange, createExchange as postExchange, editExchange as putExchange} from '../http'

interface ExchangeState {
  getExchangeData: ClientExchange | null
  getLoading: boolean
  getErrors: any
  createExchangeData: ClientExchange | null
  createLoading: boolean
  createErrors: any
  editExchangeData: ClientExchange | null
  editLoading: boolean
  editErrors: any
}

const initialState: ExchangeState = {
  getExchangeData: null,
  getLoading: false,
  getErrors: null,
  createExchangeData: null,
  createLoading: false,
  createErrors: null,
  editExchangeData: null,
  editLoading: false,
  editErrors: null,
}

export const getExchange = createAsyncThunk(
  'exchange/getExchange',
  async ({token}: {token: any;}, thunkAPI) => {
    try {     
      const response = await getAllExchange(token, 'exchange/all')
      return response.data
    } catch (error) {      
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const createExchange = createAsyncThunk(
  'exchange/createExchange',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      // const response = await postBroker(token, "user-exchange/add", body);
      const response = await postExchange(token, 'exchange/', body)
      return response.data
    } catch (error) {     
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const editExchange = createAsyncThunk(
  'exchange/editExchange',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data      
      const response = await putExchange(token, `exchange/${body.id}`, body)
      return response.data
    } catch (error) {      
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const exchangeSlice = createSlice({
  name: 'getExchange',
  initialState,
  reducers: {
    setExchange: (state, action: PayloadAction<ClientExchange>) => {
      state.getExchangeData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExchange.pending, (state, action) => {
      state.getLoading = true
      state.getExchangeData = null
    })
    builder.addCase(getExchange.fulfilled, (state, action) => {
      state.getLoading = false
      state.getExchangeData = action.payload
      state.getErrors = null
    })
    builder.addCase(getExchange.rejected, (state, action) => {
      state.getLoading = false
      state.getErrors = action.payload
      state.getExchangeData = null
    })
    builder.addCase(createExchange.pending, (state, action) => {
      state.createLoading = true
      state.createExchangeData = null
    })
    builder.addCase(createExchange.fulfilled, (state, action) => {
      state.createLoading = false
      state.createExchangeData = action.payload
      state.createErrors = null
    })
    builder.addCase(createExchange.rejected, (state, action) => {
      state.createLoading = false
      state.createErrors = action.payload
      state.createExchangeData = null
    })
    builder.addCase(editExchange.pending, (state, action) => {
      state.editLoading = true
      state.editExchangeData = null
    })
    builder.addCase(editExchange.fulfilled, (state, action) => {
      state.editLoading = false
      state.editExchangeData = action.payload
      state.editErrors = null
    })
    builder.addCase(editExchange.rejected, (state, action) => {
      state.editLoading = false
      state.editErrors = action.payload
      state.editExchangeData = null
    })
  },
})

export default exchangeSlice.reducer
export const {setExchange} = exchangeSlice.actions
