import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Strategy} from '../interfaces/Strategy'
import {
  getAllStrategy,
  editStrategy as putStrategy,
  createStrategy as postStrategy,
  subscribedStrategy as strategySubscribed,
  deleteStrategy as strategyDelete,
} from '../http'

interface StrategyState {
  strategy: Strategy | null
  loading: boolean
  errors: any
  createStrategyData: Strategy | null
  createStrategyloading: boolean
  createStrategyerrors: any
  editStrategyData: Strategy | null
  editStrategyLoading: boolean
  editStrategyErrors: any
  deleteStrategyData: Strategy | null
  deleteStrategyLoading: boolean
  deleteStrategyErrors: any
  subscribedStrategyData: Strategy | null
  subscribedStrategyLoading: boolean
  subscribedStrategyErrors: any
}

const initialState: StrategyState = {
  strategy: null,
  loading: false,
  errors: null,
  createStrategyData: null,
  createStrategyloading: false,
  createStrategyerrors: null,
  editStrategyData: null,
  editStrategyLoading: false,
  editStrategyErrors: null,
  subscribedStrategyData: null,
  subscribedStrategyLoading: false,
  subscribedStrategyErrors: null,
  deleteStrategyData: null,
  deleteStrategyLoading: false,
  deleteStrategyErrors: null
}

export const getStrategy = createAsyncThunk(
  'strategy/getStrategy',
  async ({token}: {token: any}, thunkAPI) => {
    try {
      const response = await getAllStrategy(token, 'strategy/all-strategy')
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const createStrategy = createAsyncThunk(
  'strategy/createStrategy',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      const response = await postStrategy(token, 'strategy/', body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

//Edit Strategy //
export const editStrategy = createAsyncThunk(
  'strategy/editStrategy',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      const response = await putStrategy(token, 'strategy/', body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const deleteStrategy = createAsyncThunk(
  'strategy/deleteStrategy',
  async ({token, id, data}: {token: any; id: any; data:any;}, thunkAPI) => {
    try {
      console.log(id);
      const response = await strategyDelete(token, `strategy/${id}`,data)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// Subscribed Startegy //

export const subscribedStrategy = createAsyncThunk(
  'strategy/subscribedStrategy',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      const response = await strategySubscribed(token, 'client-strategy/', body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const strategySlice = createSlice({
  name: 'getStrategy',
  initialState,
  reducers: {
    setStrategy: (state, action: PayloadAction<Strategy>) => {
      state.strategy = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStrategy.pending, (state, action) => {
      state.loading = true
      state.strategy = null
      state.errors = null
    })
    builder.addCase(getStrategy.fulfilled, (state, action) => {
      state.loading = false
      state.errors = null
      state.strategy = action.payload      
    })
    builder.addCase(getStrategy.rejected, (state, action) => {
      state.loading = false
      state.strategy = null
      state.errors = action.payload
    })
    builder.addCase(createStrategy.pending, (state, action) => {
      state.loading = true
      state.createStrategyData = null
      state.createStrategyerrors = null
    })
    builder.addCase(createStrategy.fulfilled, (state, action) => {
      state.createStrategyloading = false
      state.createStrategyerrors = null
      state.createStrategyData = action.payload      
    })
    builder.addCase(createStrategy.rejected, (state, action) => {
      state.createStrategyloading = false
      state.createStrategyData = null
      state.createStrategyerrors = action.payload
    })
    builder.addCase(editStrategy.pending, (state, action) => {
      state.editStrategyLoading = true
      state.editStrategyData = null
      state.editStrategyErrors = null
    })
    builder.addCase(editStrategy.fulfilled, (state, action) => {
      state.editStrategyLoading = false
      state.editStrategyErrors = null
      state.editStrategyData = action.payload      
    })
    builder.addCase(editStrategy.rejected, (state, action) => {
      state.editStrategyLoading = false
      state.editStrategyData = null
      state.editStrategyErrors = action.payload
    })
    builder.addCase(deleteStrategy.pending, (state, action) => {
      state.deleteStrategyLoading = true
      state.deleteStrategyData = null
      state.deleteStrategyErrors = null
    });
    builder.addCase(deleteStrategy.fulfilled, (state, action) => {
      state.deleteStrategyLoading = false
      state.deleteStrategyErrors = null
      state.deleteStrategyData = action.payload
    });
    builder.addCase(deleteStrategy.rejected, (state, action) => {
      state.deleteStrategyLoading = false
      state.deleteStrategyData = null
      state.deleteStrategyErrors = action.payload
    });
    builder.addCase(subscribedStrategy.pending, (state, action) => {
      state.subscribedStrategyLoading = true
      state.subscribedStrategyData = null
      state.subscribedStrategyErrors = null
    })
    builder.addCase(subscribedStrategy.fulfilled, (state, action) => {
      state.subscribedStrategyLoading = false
      state.subscribedStrategyErrors = null
      state.subscribedStrategyData = action.payload
      
    })
    builder.addCase(subscribedStrategy.rejected, (state, action) => {
      state.subscribedStrategyLoading = false
      state.subscribedStrategyData = null
      state.subscribedStrategyErrors = action.payload
    })
  },
})

export default strategySlice.reducer
export const {setStrategy} = strategySlice.actions
