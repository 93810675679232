export const CurrencyFormater = (value: any,currency: string, append: boolean): string => {
  let currencySymbol: string = '';

  if (append) {
      if (currency === 'INR') {
          currencySymbol = '₹';
      } else {
          currencySymbol = '$';
      }
  }
  if (!value) return `${currencySymbol} 0`;
//   const numericValue: string = parseFloat(value.toString()).toFixed(2); // Ensures proper decimal formatting
  const parsed = parseFloat(value);
  const numericValue: string = Number.isInteger(parsed) ? parsed.toFixed(0) : parsed.toFixed(2);
  
  return `${currencySymbol} ${numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const CurrencyFormaterWithAllDecimal = (value,currency, append): string => {
  let currencySymbol: string = '';

  if (append) {
      if (currency === 'INR') {
          currencySymbol = '₹';
      } else {
          currencySymbol = '$';
      }
  }
  if (!value) return `${currencySymbol} 0`;

  const numericValue: string = parseFloat(value).toString(); // Ensures proper decimal formatting
  
  return `${currencySymbol} ${numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

// export const CurrencyFormater = (value,currency, append) => {
//   if (value) {
//     // return `${append ? '₹' : ''} ${value
//     return `${append ? (currency === 'INR' ? '₹' : '$') : ''} ${value
//       .toString()
//       .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}`
//   }

//   return 0
// }
