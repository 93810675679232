import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RewardPointPlan} from '../interfaces/RewardPointPlan'
import {getAllRewardPointPlan, createRewardPointPlan as postRewardPointPlan, 
    editRewardPointPlan as putRewardPointPlan} from '../http'

interface RewardPointPlanState {
  getRewardPointPlanData: RewardPointPlan | null
  getLoading: boolean
  getErrors: any
  createRewardPointPlanData: RewardPointPlan | null
  createLoading: boolean
  createErrors: any
  editRewardPointPlanData: RewardPointPlan | null
  editLoading: boolean
  editErrors: any
}

const initialState: RewardPointPlanState = {
  getRewardPointPlanData: null,
  getLoading: false,
  getErrors: null,
  createRewardPointPlanData: null,
  createLoading: false,
  createErrors: null,
  editRewardPointPlanData: null,
  editLoading: false,
  editErrors: null,
}

export const getRewardPointPlan = createAsyncThunk(
  'rewardPointPlan/getRewardPointPlan',
  async ({token, userId}: {token: any; userId: any}, thunkAPI) => {
    try {
      const response = await getAllRewardPointPlan(token, 'reward-point-plan/all')
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const createRewardPointPlan = createAsyncThunk(
  'rewardPointPlan/createRewardPointPlan',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      // const response = await postBroker(token, "user-exchange/add", body);
      const response = await postRewardPointPlan(token, 'reward-point-plan/', body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const editRewardPointPlan = createAsyncThunk(
  'rewardPointPlan/editRewardPointPlan',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      const response = await putRewardPointPlan(token, `reward-point-plan/${body.id}`, body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const rewardPointPlanSlice = createSlice({
  name: 'getRewardPointPlan',
  initialState,
  reducers: {
    setRewardPointPlan: (state, action: PayloadAction<RewardPointPlan>) => {
      state.getRewardPointPlanData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRewardPointPlan.pending, (state, action) => {
      state.getLoading = false
      state.getRewardPointPlanData = null
    })
    builder.addCase(getRewardPointPlan.fulfilled, (state, action) => {
      state.getLoading = true
      state.getRewardPointPlanData = action.payload
      state.getErrors = null
    })
    builder.addCase(getRewardPointPlan.rejected, (state, action) => {
      state.getLoading = false
      state.getErrors = action.payload
      state.getRewardPointPlanData = null
    })
    builder.addCase(createRewardPointPlan.pending, (state, action) => {
      state.createLoading = true
      state.createRewardPointPlanData = null
    })
    builder.addCase(createRewardPointPlan.fulfilled, (state, action) => {
      state.createLoading = true
      state.createRewardPointPlanData = action.payload
      state.createErrors = null
    })
    builder.addCase(createRewardPointPlan.rejected, (state, action) => {
      state.createLoading = true
      state.createErrors = action.payload
      state.createRewardPointPlanData = null
    })
    builder.addCase(editRewardPointPlan.pending, (state, action) => {
      state.editLoading = false
      state.editRewardPointPlanData = null
    })
    builder.addCase(editRewardPointPlan.fulfilled, (state, action) => {
      state.editLoading = true
      state.editRewardPointPlanData = action.payload
      state.editErrors = null
    })
    builder.addCase(editRewardPointPlan.rejected, (state, action) => {
      state.editLoading = true
      state.editErrors = action.payload
      state.editRewardPointPlanData = null
    })
  },
})

export default rewardPointPlanSlice.reducer
export const {setRewardPointPlan} = rewardPointPlanSlice.actions
