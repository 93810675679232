import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { TextField, Button, Box, Autocomplete } from '@mui/material';
import { verifyOTP, sendOTP } from '../../http';
import { countryCode as countryCodeList } from '../../helpers';

export type IDataProps = {
    onClose: (value) => void
}

const initialValuesSendOTP = {
    email: '',
    phoneNumber: '',
    countryObject: { countryCode: 'IN', label: 'India', phone: '+91' }
};

const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .max(50, 'Email should be maximum 50 characters')
        .required('Email is required'),
    countryObject: Yup.object().shape({
        countryCode: Yup.string().nullable().required('Country code is required'),
        label: Yup.string().nullable().required(),
        phone: Yup.string().nullable().required(),
    }),
    phoneNumber: Yup.string()
        .trim()
        .required('Phone number is required')
        .matches(phoneRegExp, 'Phone number is not valid'),
});

const initialValuesOtp: any = {
    otp: '',
};

const otpFormValidationSchema = Yup.object().shape({
    otp: Yup.string()
        .nullable()
        .required('OTP is required')
        .max(6, 'Otp must be 6 digits')
        .matches(/^\d+$/, "Otp should be numeric")
});

export function SendOtpCard(props: IDataProps) {

    const [loadingSendOtp, setLoadingSendOtp] = useState(false);
    const [loadingVerifyOtp, setLoadingVerifyOtp] = useState(false);
    const [showOtpSendScreen, setShowOtpSendScreen] = useState(true);
    const [emailId, setEmailId] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [countryIsdCode, setCountryIsdCode] = useState('');
    const [seconds, setSeconds] = useState(60);
    const [otpVerificationId, setOtpVerificationId] = useState();
    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState<string>('success');
    const [alertMessage, setAlertMessage] = useState('');

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const handleResendOTP = async () => {
        // code to resend OTP
        const body = {
            emailAddress: emailId,
            phoneNumber: `${countryIsdCode}${phoneNo}`,
        }
        try {
            const sendOTPResponse: any = await sendOTP("auth/forgot-password", body);

            setShowOtpSendScreen(!sendOTPResponse?.data?.requestProcessed);
            if (sendOTPResponse?.data?.requestProcessed) {
                setOtpVerificationId(sendOTPResponse?.data?.verificationRequestId);
                setSeconds(60);
            }
        } catch (error: any) {
            setOpenAlert(true);
            setAlertMessage(error?.response?.data?.apierror?.message);
            setAlertType('error');
        }

    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {                
                clearInterval(interval);              
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const formikSendOtp = useFormik({
        initialValues: initialValuesSendOTP,
        validationSchema: forgotPasswordSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoadingSendOtp(true);           
            const body = {
                emailAddress: values.email,
                phoneNumber: `${values.countryObject.phone}${values.phoneNumber}`,
            }
            try {
                const sendOTPResponse: any = await sendOTP("auth/forgot-password", body);

                setShowOtpSendScreen(!sendOTPResponse?.data?.requestProcessed);
                if (sendOTPResponse?.data?.requestProcessed) {
                    setEmailId(values.email);
                    setPhoneNo(values.phoneNumber);
                    setCountryIsdCode(values.countryObject.phone);
                    setOtpVerificationId(sendOTPResponse?.data?.verificationRequestId);
                    setSeconds(60);
                }
            } catch (error: any) {
                setOpenAlert(true);
                setAlertMessage(error?.response?.data?.apierror?.message);
                setAlertType('error');
            }
            setLoadingSendOtp(false);
        },
    });

    const formikVerifyOtp = useFormik<any>({
        initialValues: initialValuesOtp,
        validationSchema: otpFormValidationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoadingVerifyOtp(true)
            const body = {
                target: `${countryIsdCode}${phoneNo}`,
                type: "SMS",
                purpose: "FORGOT_PASSWORD",
                verificationCode: values.otp
            };
            try {
                const response = await verifyOTP("auth/verify/otp", body)
                if (response.data.verified) {
                    props.onClose({
                        phoneNo: `${countryIsdCode}${phoneNo}`,
                        email: emailId,
                        otpVerificationId: otpVerificationId,
                        isShowResetPwdScreen: true
                    });
                }
                else {
                    setOpenAlert(true);
                    setAlertMessage('Please enter valid otp.');
                    setAlertType('error');
                }

            }
            catch (error: any) {
                setOpenAlert(true);
                setAlertMessage(error?.response?.data?.apierror?.message);
                setAlertType('error');
            }
            setLoadingVerifyOtp(false)
        },
    });

    function renderOption(props, option) {
        return (
          <Box component="li" sx={{ '& > img': { mr: 1, flexShrink: 0, fontSize: 6 } }} {...props}>
            <img
              loading="lazy"
              width="15"
              src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 1x`}
              alt=""
            />
            <span className='fs-9'> {option.phone}-{option.label}</span>
          </Box>
        );
      }

    return (
        <>
            {showOtpSendScreen ?
                <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_password_reset_form'
                    onSubmit={formikSendOtp.handleSubmit}
                >
                    <div className='text-center mb-10'>
                        {/* begin::Title */}
                        <h1 className='text-dark mb-3'>Forgot Password ?</h1>
                        {/* end::Title */}

                        {/* begin::Link */}
                        <div className='text-gray-400 fw-bold fs-6'>Enter your registered <b>email-id</b> and <b>phone no.</b> to reset your password.</div>
                        {/* end::Link */}
                    </div>                  
                    {/* begin::Form group */}
                    <div className='fv-row mb-4'>
                        {/* <label className='form-label fw-bolder text-gray-900 fs-6'>Email-Id</label> */}
                        <TextField
                            type='email'
                            size='medium'
                            placeholder='Please enter your Email-Id'
                            label='Email-Id'
                            autoComplete='off'
                            {...formikSendOtp.getFieldProps('email')}
                            InputLabelProps={{
                                className: 'fw-bolder required'
                            }}                            
                            className='form-control form-control-lg form-control-solid'
                        />
                        {formikSendOtp.touched.email && formikSendOtp.errors.email && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formikSendOtp.errors.email?.toString()}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='fv-row d-flex'>
                        <Autocomplete
                            disablePortal
                            id="Exchange"
                            options={countryCodeList}
                            getOptionLabel={(option) => option.phone}
                            size='medium'
                            clearIcon={false}
                            renderOption={renderOption}
                            value={formikSendOtp.values.countryObject}
                            onChange={(e, value) => { formikSendOtp.setFieldValue('countryObject', value) }}
                            renderInput={(params) => (<TextField {...params} label="Code" placeholder='select...' 
                                className='form-control form-control-lg form-control-solid'
                                InputLabelProps={{
                                    className: 'fw-bolder required'
                                }}                               
                            />)}
                        />
                        {formikSendOtp.touched.countryObject && formikSendOtp.errors.countryObject?.countryCode && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formikSendOtp.errors.countryObject.countryCode}</div>
                            </div>
                        )}
                        <TextField
                            type='tel'
                            size='medium'
                            placeholder='Please enter your Phone No.'
                            label='Phone No.'
                            autoComplete='off'
                            {...formikSendOtp.getFieldProps('phoneNumber')}
                            InputLabelProps={{
                                className: 'fw-bolder required'
                            }}
                            className='form-control form-control-lg form-control-solid ms-2'
                        />
                        
                    </div>
                    <div className='fv-row d-flex mb-6'>
                    {formikSendOtp.touched.phoneNumber && formikSendOtp.errors.phoneNumber && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formikSendOtp.errors.phoneNumber?.toString()}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                        <Button
                            type='submit'
                            id='kt_password_reset_submit'
                            variant="contained"
                            color="info"
                            className='fw-bolder me-4'
                            size="large"
                        >
                            <span className='indicator-label'>Submit</span>
                            {loadingSendOtp && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </Button>
                        {/* <Link to='/auth/login'> */}
                        <Link to='/auth/sign-in'>
                            <Button
                                variant="contained"
                                type='button'
                                color="inherit"
                                size="large"
                                id='kt_login_password_reset_form_cancel_button'
                                // disabled={formikSendOtp.isSubmitting || !formikSendOtp.isValid}
                            >
                                Cancel
                            </Button>
                        </Link>{' '}
                    </div>
                    {/* end::Form group */}
                </form> :
                <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_password_reset_form'
                    onSubmit={formikVerifyOtp.handleSubmit}
                >
                    <div className='text-center mb-10'>
                        {/* begin::Title */}
                        <h1 className='text-dark mb-3'>Verify OTP ?</h1>
                        {/* end::Title */}

                        {/* begin::Link */}
                        <div className='text-gray-400 fw-bold fs-6'>OTP send to your registered phone no. ******{phoneNo && phoneNo.substring(phoneNo.length - 4, phoneNo.length)}.</div>
                        {/* end::Link */}
                    </div>

                    {/* begin::Form group */}
                    <div className='fv-row mb-10'>
                        {/* <label className='form-label fw-bolder text-gray-900 fs-6'>Email-Id</label> */}
                        <TextField
                            type='text'
                            placeholder='Please enter otp'
                            label='OTP'
                            autoComplete='off'
                            {...formikVerifyOtp.getFieldProps('otp')}
                            InputLabelProps={{
                                className: 'form-label fw-bolder required text-gray-900 fs-6'
                            }}
                            className='form-control form-control-lg form-control-solid'
                        />
                        {formikVerifyOtp.touched.otp && formikVerifyOtp.errors.otp && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formikVerifyOtp.errors.otp?.toString()}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='fv-row mb-0'>
                        <div className='d-flex countdown-text fs-8'>
                            {seconds > 0 ? (
                                <p>
                                    Time Remaining : 00:{seconds < 10 ? `0${seconds}` : seconds}
                                </p>
                            ) : (
                                <p>Didn't recieve code?</p>
                            )}
                            <span
                                style={{
                                    marginLeft: 5,
                                    cursor: 'pointer',
                                    color: seconds > 0 ? "#392f28" : "#0095e8",
                                    textDecorationLine: 'underline',
                                    pointerEvents: seconds > 0 ? 'none' : 'auto'

                                }}
                                onClick={handleResendOTP}
                            >
                                Resend OTP
                            </span>
                        </div>
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                        <Button
                            type='submit'
                            id='kt_password_reset_submit'
                            variant="contained"
                            color="info"
                            className='fw-bolder me-4'
                            size="large"
                        >
                            <span className='indicator-label'>Submit</span>
                            {loadingVerifyOtp && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </Button>
                        {/* <Link to='/auth/login'> */}
                        <Link to='/auth/sign-in'>
                            <Button
                                variant="contained"
                                type='button'
                                color="inherit"
                                size="large"
                                id='kt_login_password_reset_form_cancel_button'
                            // disabled={formikVerifyOtp.isSubmitting || !formikVerifyOtp.isValid}
                            >
                                Cancel
                            </Button>
                        </Link>{' '}
                    </div>
                    {/* end::Form group */}
                </form>}
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackbarClose} severity={alertType == 'success' ? 'success' : 'error'}>{alertMessage}</Alert>
            </Snackbar>
        </>
    )
}
