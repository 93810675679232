/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { Dropdown1 } from '../../../../_metronic/partials/content/dropdown/Dropdown1'
import { getAlgoActivity } from '../../../modules/reducers/algoActivitySlice'
import { useAppDispatch, useAppSelector } from '../../../../Store'
import { useAuth } from '../../../modules/auth'
import { Data } from '../../../modules/interfaces/AlgoActivity'
import moment from 'moment';

type Props = {
    className: string    
    cardHeight: string
}

const AlgoActivity: React.FC<Props> = ({ className, cardHeight }) => {
    const dispatch = useAppDispatch();
    const token = useAuth().auth?.token;
    const { algoActivityData, loading } = useAppSelector((state) => state.algoActivity);
    const [algoData, setAlgoData] = useState<Data[]>()

    const body = {
        token: token,
    }

    useEffect(() => {
        dispatch(getAlgoActivity(body))
    }, [dispatch]);

    useEffect(() => {
        setAlgoData(algoActivityData?.modal?.data)
    }, [algoActivityData]);

    const handleBadgeColor = (status: any) => {
        if (status === 'SUCCESS') {
            return 'text-success';
        } else if (status === 'FAILED') {
            return 'text-danger';
        } else {
            return 'text-warning';
        }
    };

    const handleMessage = (value: any) => {
        let messageParts: JSX.Element[] = [];
    
        if (value?.status.toUpperCase() === 'FAILED') {
            messageParts.push(
                <span style={{color: '#CB4335', fontWeight: 'bold'}}>{parseInt(value?.qty)} qty sell </span>,
                <span>orders for </span>,
                <span style={{ color: '#CB4335', fontWeight: 'bold' }}>{value.algoName}</span>,
                <span> on </span>,
                <span style={{ color: '#CB4335', fontWeight: 'bold' }}>{value.exchangeName}</span>,
                <span> were triggered, but unfortunately, they </span>,
                <span style={{ color: '#CB4335', fontWeight: 'bold' }}>{value.status}.</span>
            );
        } else {
            messageParts.push(
                <span style={{color: '#008000', fontWeight: 'bold'}}>{parseInt(value?.qty)} qty sell </span>,
                <span>orders for </span>,
                <span style={{ color: '#008000', fontWeight: 'bold' }}>{value.algoName}</span>,
                <span> on </span>,
                <span style={{ color: '#008000', fontWeight: 'bold' }}>{value.exchangeName}</span>,
                <span> were successfully executed.</span>
            );
        }
    
        return messageParts;
    }

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header align-items-center border-0 mt-4'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bold mb-2 text-dark'>Algo Activities</span>
                    <span className='text-muted fw-bold fs-7'>{algoData?.length ? algoData?.length : 0} Transactions</span>
                </h3>
                {/* <div className='card-toolbar'>
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                    </button>
                    <Dropdown1 />
                </div> */}
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body pt-5' style={{ maxHeight: '500px', overflowY: 'auto' }}>
                {/* begin::Timeline */}
                {algoData && algoData.length > 0 ? (
                    <div className='timeline-label'>
                        {algoData.map((item, index) => (
                            <div className='timeline-item' key={index}>
                                <div className='timeline-label fw-bold text-gray-800 small' style={{fontSize:'8px'}}>
                                    {moment(item.tradedDateTime).format('DDMMMYY hh:MM')}
                                </div>
                                <div className='timeline-badge'>
                                    <i className={`fa fa-genderless fs-3 ${handleBadgeColor(item?.status)}`}></i>
                                </div>
                                <div className='fw-mormal timeline-content ps-3 fs-8' style={{ color: item?.status?.toUpperCase() === 'FAILED' ? '#F34D4A' : '#7CFC00' }}>
                                    {handleMessage(item)}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='text-center text-muted fw-bold fs-3 mt-12'>No Algo Activity Performed</div>
                )}
                {/* end::Timeline */}
            </div>
            {/* end: Card Body */}
        </div>
    );
}

export { AlgoActivity }
