import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {InstrumentMaster} from '../interfaces/InstrumentMaster'
import {getAllInstrument, getAllInstrumentByFilter} from '../http'

interface InstrumentState {
  getInstrumentData: any | null
  getInstrumentLoading: boolean
  getInstrumentErrors: any  
  getInstrumentFilterData: InstrumentMaster | null
  getInstrumentFilterLoading: boolean
  getInstrumentFilterErrors: any  
}

const initialState: InstrumentState = {
  getInstrumentData: null,
  getInstrumentLoading: false,
  getInstrumentErrors: null,  
  getInstrumentFilterData: null,
  getInstrumentFilterLoading: false,
  getInstrumentFilterErrors: null,
}

export const getInstrument = createAsyncThunk(
  'instrument/getInstrument',
  async ({token}: {token: any}, thunkAPI) => {
    try {
      const response = await getAllInstrument(token, 'instrument-master/filters')
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getInstrumentByFilter = createAsyncThunk(
  'instrument/getInstrumentByFilter',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data;
      // const response = await getAllInstrumentByFilter(token, 'instrument-master/by-filter', body);
      const response = await getAllInstrument(token, 'instrument-master/all');
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const instrumentMasterSlice = createSlice({
  name: 'getInstrument',
  initialState,
  reducers: {
    setInstrument: (state, action: PayloadAction<InstrumentMaster>) => {
      state.getInstrumentData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInstrument.pending, (state, action) => {
      state.getInstrumentLoading = true
      state.getInstrumentData = null
    })
    builder.addCase(getInstrument.fulfilled, (state, action) => {
      state.getInstrumentLoading = false
      state.getInstrumentData = action.payload
      state.getInstrumentErrors = null
    })
    builder.addCase(getInstrument.rejected, (state, action) => {
      state.getInstrumentLoading = false
      state.getInstrumentErrors = action.payload
      state.getInstrumentData = null
    })
    builder.addCase(getInstrumentByFilter.pending, (state, action) => {
      state.getInstrumentFilterLoading = true
      state.getInstrumentFilterData = null
    })
    builder.addCase(getInstrumentByFilter.fulfilled, (state, action) => {
      state.getInstrumentFilterLoading = false
      state.getInstrumentFilterData = action.payload
      state.getInstrumentFilterErrors = null
    })
    builder.addCase(getInstrumentByFilter.rejected, (state, action) => {
      state.getInstrumentFilterLoading = false
      state.getInstrumentFilterErrors = action.payload
      state.getInstrumentFilterData = null
    })      
  },
})

export default instrumentMasterSlice.reducer
export const {setInstrument} = instrumentMasterSlice.actions
