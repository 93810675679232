import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {StrategyIndicatorConfirgAdaptor} from '../interfaces/StrategyIndicatorConfirgAdaptor'
import {
  getAllStrategyIndicatorConfigIndicator 
} from '../http'

interface StrategyIndicatorConfigAdaptorState {
  getStrategyIndicatorConfirgAdaptorData: StrategyIndicatorConfirgAdaptor | null
  getLoading: boolean
  getErrors: any  
}

const initialState: StrategyIndicatorConfigAdaptorState = {
  getStrategyIndicatorConfirgAdaptorData: null,
  getLoading: false,
  getErrors: null,  
}

export const getStrategyIndicatorConfirgAdaptor = createAsyncThunk(
  'strategyIndicatorConfigAdaptor/getStrategyIndicatorConfirgAdaptor',
  async ({token}: {token: any;}, thunkAPI) => {
    try {     
      const response = await getAllStrategyIndicatorConfigIndicator(token, 'indicator-config-adaptor/all')
      return response.data
    } catch (error) {      
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const strategyIndicatorConfigAdaptorSlice = createSlice({
  name: 'getStrategyIndicatorConfirgAdaptor',
  initialState,
  reducers: {
    setStrategyIndicatorConfirgAdaptor: (state, action: PayloadAction<StrategyIndicatorConfirgAdaptor>) => {
      state.getStrategyIndicatorConfirgAdaptorData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStrategyIndicatorConfirgAdaptor.pending, (state, action) => {
      state.getLoading = true
      state.getStrategyIndicatorConfirgAdaptorData = null
    })
    builder.addCase(getStrategyIndicatorConfirgAdaptor.fulfilled, (state, action) => {
      state.getLoading = false
      state.getStrategyIndicatorConfirgAdaptorData = action.payload
      state.getErrors = null
    })
    builder.addCase(getStrategyIndicatorConfirgAdaptor.rejected, (state, action) => {
      state.getLoading = false
      state.getErrors = action.payload
      state.getStrategyIndicatorConfirgAdaptorData = null
    })    
  },
})

export default strategyIndicatorConfigAdaptorSlice.reducer
export const {setStrategyIndicatorConfirgAdaptor} = strategyIndicatorConfigAdaptorSlice.actions
