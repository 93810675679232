import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {DiscountCoupon} from '../interfaces/DiscountCoupon'
import {getAllLazyDiscountCoupon, createDiscountCoupon as postDiscountCoupon, 
    editDiscountCoupon as putDiscountCoupon } from '../http'

interface DiscountCouponState {
  getLazyDiscountCouponData: DiscountCoupon | null
  getLoading: boolean
  getErrors: any
  createDiscountCouponData: DiscountCoupon | null
  createLoading: boolean
  createErrors: any
  editDiscountCouponData: DiscountCoupon | null
  editLoading: boolean
  editErrors: any  
}

const initialState: DiscountCouponState = {
  getLazyDiscountCouponData: null,
  getLoading: false,
  getErrors: null,
  createDiscountCouponData: null,
  createLoading: false,
  createErrors: null,
  editDiscountCouponData: null,
  editLoading: false,
  editErrors: null,  
}

export const getAllLazyDiscountCouponData = createAsyncThunk(
  'discountCoupon/getAllLazyDiscountCouponData',
  async ({token}: {token: any;}, thunkAPI) => {
    try {     
      const response = await getAllLazyDiscountCoupon(token, 'discount-coupon-master/all-lazy')
      return response.data
    } catch (error) {      
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const createDiscountCoupon = createAsyncThunk(
  'discountCoupon/createDiscountCoupon',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      // const response = await postBroker(token, "user-exchange/add", body);
      const response = await postDiscountCoupon(token, 'discount-coupon-master/', body)
      return response.data
    } catch (error) {      
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const editDiscountCoupon = createAsyncThunk(
  'discountCoupon/editDiscountCoupon',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data      
      const response = await putDiscountCoupon(token, `discount-coupon-master/${body.id}`, body)
      return response.data
    } catch (error) {      
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const discountCouponSlice = createSlice({
  name: 'discountCoupon',
  initialState,
  reducers: {
    setDiscountCoupon: (state, action: PayloadAction<DiscountCoupon>) => {
      state.getLazyDiscountCouponData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllLazyDiscountCouponData.pending, (state, action) => {
      state.getLoading = false
      state.getLazyDiscountCouponData = null
    })
    builder.addCase(getAllLazyDiscountCouponData.fulfilled, (state, action) => {
      state.getLoading = true
      state.getLazyDiscountCouponData = action.payload
      state.getErrors = null
    })
    builder.addCase(getAllLazyDiscountCouponData.rejected, (state, action) => {
      state.getLoading = false
      state.getErrors = action.payload
      state.getLazyDiscountCouponData = null
    })
    builder.addCase(createDiscountCoupon.pending, (state, action) => {
      state.createLoading = true
      state.createDiscountCouponData = null
    })
    builder.addCase(createDiscountCoupon.fulfilled, (state, action) => {
      state.createLoading = true
      state.createDiscountCouponData = action.payload
      state.createErrors = null
    })
    builder.addCase(createDiscountCoupon.rejected, (state, action) => {
      state.createLoading = true
      state.createErrors = action.payload
      state.createDiscountCouponData = null
    })
    builder.addCase(editDiscountCoupon.pending, (state, action) => {
      state.editLoading = false
      state.editDiscountCouponData = null
    })
    builder.addCase(editDiscountCoupon.fulfilled, (state, action) => {
      state.editLoading = true
      state.editDiscountCouponData = action.payload
      state.editErrors = null
    })
    builder.addCase(editDiscountCoupon.rejected, (state, action) => {
      state.editLoading = true
      state.editErrors = action.payload
      state.editDiscountCouponData = null
    })
  },
})

export default discountCouponSlice.reducer
export const {setDiscountCoupon} = discountCouponSlice.actions
