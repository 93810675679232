import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Indicator} from '../interfaces/indicator'
import {getAllIndicator, createIndicator as postIndicator, editIndicator as putIndicator} from '../http'

interface IndicatorState {
  getIndicatorData: Indicator | null
  getLoading: boolean
  getErrors: any
  createIndicatorData: Indicator | null
  createLoading: boolean
  createErrors: any
  editIndicatorData: Indicator | null
  editLoading: boolean
  editErrors: any
}

const initialState: IndicatorState = {
  getIndicatorData: null,
  getLoading: false,
  getErrors: null,
  createIndicatorData: null,
  createLoading: false,
  createErrors: null,
  editIndicatorData: null,
  editLoading: false,
  editErrors: null,
}

export const getIndicator = createAsyncThunk(
  'indicator/getIndicator',
  async ({token}: {token: any;}, thunkAPI) => {
    try {     
      const response = await getAllIndicator(token, 'indicator-master/all')
      return response.data
    } catch (error) {      
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const createIndicator = createAsyncThunk(
  'indicator/createIndicator',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data      
      const response = await postIndicator(token, 'indicator-master/', body)
      return response.data
    } catch (error) {     
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const editIndicator = createAsyncThunk(
  'indicator/editIndicator',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data      
      const response = await putIndicator(token, `indicator-master/${body.id}`, body)
      return response.data
    } catch (error) {      
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const indicatorSlice = createSlice({
  name: 'getIndicator',
  initialState,
  reducers: {
    setIndicator: (state, action: PayloadAction<Indicator>) => {
      state.getIndicatorData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIndicator.pending, (state, action) => {
      state.getLoading = true
      state.getIndicatorData = null
    })
    builder.addCase(getIndicator.fulfilled, (state, action) => {
      state.getLoading = false
      state.getIndicatorData = action.payload
      state.getErrors = null
    })
    builder.addCase(getIndicator.rejected, (state, action) => {
      state.getLoading = false
      state.getErrors = action.payload
      state.getIndicatorData = null
    })
    builder.addCase(createIndicator.pending, (state, action) => {
      state.createLoading = true
      state.createIndicatorData = null
    })
    builder.addCase(createIndicator.fulfilled, (state, action) => {
      state.createLoading = false
      state.createIndicatorData = action.payload
      state.createErrors = null
    })
    builder.addCase(createIndicator.rejected, (state, action) => {
      state.createLoading = false
      state.createErrors = action.payload
      state.createIndicatorData = null
    })
    builder.addCase(editIndicator.pending, (state, action) => {
      state.editLoading = true
      state.editIndicatorData = null
    })
    builder.addCase(editIndicator.fulfilled, (state, action) => {
      state.editLoading = false
      state.editIndicatorData = action.payload
      state.editErrors = null
    })
    builder.addCase(editIndicator.rejected, (state, action) => {
      state.editLoading = false
      state.editErrors = action.payload
      state.editIndicatorData = null
    })
  },
})

export default indicatorSlice.reducer
export const {setIndicator} = indicatorSlice.actions
