import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RewardPointValue} from '../interfaces/RewardPointValue'
import {getAllRewardPointValues, createRewardPointValue as postRewardPointValue, 
    editRewardPointValue as putRewardPointValue} from '../http'

interface RewardPointValueState {
  getRewardPointValueData: RewardPointValue | null
  getLoading: boolean
  getErrors: any
  createRewardPointValueData: RewardPointValue | null
  createLoading: boolean
  createErrors: any
  editRewardPointValueData: RewardPointValue | null
  editLoading: boolean
  editErrors: any
}

const initialState: RewardPointValueState = {
  getRewardPointValueData: null,
  getLoading: false,
  getErrors: null,
  createRewardPointValueData: null,
  createLoading: false,
  createErrors: null,
  editRewardPointValueData: null,
  editLoading: false,
  editErrors: null,
}

export const getRewardPointValue = createAsyncThunk(
  'rewardPointPlan/getRewardPointValue',
  async ({token, userId}: {token: any; userId: any}, thunkAPI) => {
    try {      
      const response = await getAllRewardPointValues(token, 'reward-point-value/all')
      return response.data
    } catch (error) {      
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const createRewardPointValue = createAsyncThunk(
  'rewardPointValue/createRewardPointValue',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      // const response = await postBroker(token, "user-exchange/add", body);
      const response = await postRewardPointValue(token, 'reward-point-value/', body)
      return response.data
    } catch (error) {      
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const editRewardPointValue = createAsyncThunk(
  'rewardPointValue/editRewardPointValue',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      const response = await putRewardPointValue(token, `reward-point-value/${body.id}`, body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const rewardPointValueSlice = createSlice({
  name: 'getRewardPointValue',
  initialState,
  reducers: {
    setRewardPointValue: (state, action: PayloadAction<RewardPointValue>) => {
      state.getRewardPointValueData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRewardPointValue.pending, (state, action) => {
      state.getLoading = false
      state.getRewardPointValueData = null
    })
    builder.addCase(getRewardPointValue.fulfilled, (state, action) => {
      state.getLoading = true
      state.getRewardPointValueData = action.payload
      state.getErrors = null
    })
    builder.addCase(getRewardPointValue.rejected, (state, action) => {
      state.getLoading = false
      state.getErrors = action.payload
      state.getRewardPointValueData = null
    })
    builder.addCase(createRewardPointValue.pending, (state, action) => {
      state.createLoading = true
      state.createRewardPointValueData = null
    })
    builder.addCase(createRewardPointValue.fulfilled, (state, action) => {
      state.createLoading = true
      state.createRewardPointValueData = action.payload
      state.createErrors = null
    })
    builder.addCase(createRewardPointValue.rejected, (state, action) => {
      state.createLoading = true
      state.createErrors = action.payload
      state.createRewardPointValueData = null
    })
    builder.addCase(editRewardPointValue.pending, (state, action) => {
      state.editLoading = false
      state.editRewardPointValueData = null
    })
    builder.addCase(editRewardPointValue.fulfilled, (state, action) => {
      state.editLoading = true
      state.editRewardPointValueData = action.payload
      state.editErrors = null
    })
    builder.addCase(editRewardPointValue.rejected, (state, action) => {
      state.editLoading = true
      state.editErrors = action.payload
      state.editRewardPointValueData = null
    })
  },
})

export default rewardPointValueSlice.reducer
export const {setRewardPointValue} = rewardPointValueSlice.actions
