import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Data} from '../interfaces/MultiUserExchange'
import {
    getMultiUserExchange  as fetchMultiUserExchangeData
} from '../http'

interface ClientExchangeState {
  getMultiUserExchangeData: Data | null
  getMultiUserExchangeLoading: boolean
  getMultiUserExchangeErrors: any  
  getMultiUserExchangeOrderData: Data | null
  getMultiUserExchangeOrderLoading: boolean
  getMultiUserExchangeOrderErrors: any
  getMultiUserExchangePortfolioData: Data | null
  getMultiUserExchangePortfolioLoading: boolean
  getMultiUserExchangePortfolioErrors: any
}

const initialState: ClientExchangeState = {
  getMultiUserExchangeData: null,
  getMultiUserExchangeLoading: false,
  getMultiUserExchangeErrors: null,  
  getMultiUserExchangeOrderData: null,
  getMultiUserExchangeOrderLoading: false,
  getMultiUserExchangeOrderErrors: null,  
  getMultiUserExchangePortfolioData: null,
  getMultiUserExchangePortfolioLoading: false,
  getMultiUserExchangePortfolioErrors: null,  
}

export const getMultiUserExchange = createAsyncThunk(
  'multiUserExchange/getMultiUserExchange',
  async ({data}: {data: any}, thunkAPI) => {
    try {
        const {token, body} = data
        const response = await fetchMultiUserExchangeData(token, 'user-multi-exchange/account-info', body)
        return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getMultiUserExchangeOrder = createAsyncThunk(
  'multiUserExchange/getMultiUserExchangeOrder',
  async ({data}: {data: any}, thunkAPI) => {
    try {
        const {token, body} = data
        const response = await fetchMultiUserExchangeData(token, 'user-multi-exchange/account-info', body)
        return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getMultiUserExchangePortfolio = createAsyncThunk(
  'multiUserExchange/getMultiUserExchangePortfolio',
  async ({data}: {data: any}, thunkAPI) => {
    try {
        const {token, body} = data
        const response = await fetchMultiUserExchangeData(token, 'user-multi-exchange/account-info', body)
        return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const multiUserExchangeSlice = createSlice({
  name: 'getMultiUserExchange',
  initialState,
  reducers: {
    setmultiUserExchange: (state, action: PayloadAction<Data>) => {
      state.getMultiUserExchangeData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMultiUserExchange.pending, (state, action) => {
      state.getMultiUserExchangeLoading = false
      state.getMultiUserExchangeData = null
    })
    builder.addCase(getMultiUserExchange.fulfilled, (state, action) => {
      state.getMultiUserExchangeLoading = true
      state.getMultiUserExchangeData = action.payload
      state.getMultiUserExchangeErrors = null
    })
    builder.addCase(getMultiUserExchange.rejected, (state, action) => {
      state.getMultiUserExchangeLoading = true
      state.getMultiUserExchangeData = null
      state.getMultiUserExchangeErrors = action.payload
    })    
    builder.addCase(getMultiUserExchangeOrder.pending, (state, action) => {
      state.getMultiUserExchangeOrderLoading = false
      state.getMultiUserExchangeOrderData = null
    })
    builder.addCase(getMultiUserExchangeOrder.fulfilled, (state, action) => {
      state.getMultiUserExchangeOrderLoading = true
      state.getMultiUserExchangeOrderData = action.payload
      state.getMultiUserExchangeOrderErrors = null
    })
    builder.addCase(getMultiUserExchangeOrder.rejected, (state, action) => {
      state.getMultiUserExchangeOrderLoading = true
      state.getMultiUserExchangeOrderData = null
      state.getMultiUserExchangeOrderErrors = action.payload
    })    
    builder.addCase(getMultiUserExchangePortfolio.pending, (state, action) => {
      state.getMultiUserExchangePortfolioLoading = false
      // state.getMultiUserExchangePortfolioData = null
    })
    builder.addCase(getMultiUserExchangePortfolio.fulfilled, (state, action) => {
      state.getMultiUserExchangePortfolioLoading = true
      state.getMultiUserExchangePortfolioData = action.payload
      state.getMultiUserExchangePortfolioErrors = null
    })
    builder.addCase(getMultiUserExchangePortfolio.rejected, (state, action) => {
      state.getMultiUserExchangePortfolioLoading = true
      state.getMultiUserExchangePortfolioData = null
      state.getMultiUserExchangePortfolioErrors = action.payload
    })    
  },
})

export default multiUserExchangeSlice.reducer
export const {setmultiUserExchange} = multiUserExchangeSlice.actions
