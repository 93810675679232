import { FC, useEffect, useState } from 'react'
import { useAuth } from '../../modules/auth';
import { getZerodhaToken } from '../../modules/http';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const Ticket: FC = () => {
    const [data, setData] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [exchangeAuthToken, setExchangeAuthToken] = useState<string | null>(null);
    const [exchangeName, setExchangeName] = useState<string | null>(null);
    const token = useAuth().auth?.token;

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await fetch('https://dev-til-services.tradeitlazy.com/api/ticket/number'); // Replace this URL with your API endpoint
            const result = await response.text(); // Parse the response as text
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const getRahulZerodhaToken = async () => {
        try {
            const response = await getZerodhaToken(token, "client-exchange/exchange-info/Rahul-Zerodha");
            setExchangeAuthToken(response.data.exchangeAuthToken);
            setExchangeName(response.data.accountTag);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getRahulZerodhaToken();
    }, [])

    return (
        <>
            <div className="position-relative">
                <div className="position-absolute top-0 left-0 p-3" style={{ fontSize: '1rem' }}>
                    {exchangeAuthToken && exchangeName && (
                        <div>
                            The exchange auth token for <b>{exchangeName}</b> is 
                            <br />
                            <b>{exchangeAuthToken}</b>
                        </div>
                    )}
                </div>
                <div className="d-flex flex-column align-items-center">
                    <button className="btn btn-primary mb-2" onClick={fetchData}>
                        Generate New Ticket Number
                    </button>
                    <div className="mt-2">
                        <input
                            className="form-control"
                            type="text"
                            readOnly
                            value={loading ? "" : data ? `${data}` : ""}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export { Ticket }
