import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ClientExchange} from '../interfaces/ClientExchange'
import {
  getClientExchangeByUser,
  createClientExchange as postClientExchange,
  editClientExchange as putClientExchange,
  getAllClientExchangeByUser,
} from '../http'

interface ClientExchangeState {
  getClientExchangeData: ClientExchange | null
  getLoading: boolean
  getErrors: any
  createClientExchangeData: ClientExchange | null
  createLoading: boolean
  createErrors: any
  editClientExchangeData: ClientExchange | null
  editLoading: boolean
  editErrors: any
  getAllClientExchangeData: ClientExchange | null
  getAllLoading: boolean
  getAllErrors: any
}

const initialState: ClientExchangeState = {
  getClientExchangeData: null,
  getLoading: false,
  getErrors: null,
  createClientExchangeData: null,
  createLoading: false,
  createErrors: null,
  editClientExchangeData: null,
  editLoading: false,
  editErrors: null,
  getAllClientExchangeData: null,
  getAllLoading: false,
  getAllErrors: null,
}

export const getClientExchange = createAsyncThunk(
  'clientExchange/getClientExchange',
  async ({token, userId}: {token: any; userId: any}, thunkAPI) => {
    try {     
      const response = await getAllClientExchangeByUser(token, 'client-exchange/all')
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getClientExchangeForAlgoTrans = createAsyncThunk(
  'clientExchange/getClientExchangeForAlgoTrans',
  async ({token}: {token: any}, thunkAPI) => {
    try {     
      const response = await getAllClientExchangeByUser(token, 'client-exchange/all-exchange')
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getClientExchangeForIndivisual = createAsyncThunk(
  'clientExchange/getClientExchangeForIndivisual',
  async ({token}: {token: any;}, thunkAPI) => {
    try {
      const response = await getClientExchangeByUser(token, 'client-exchange/exchange')
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const createClientExchange = createAsyncThunk(
  'clientExchange/createClientExchange',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      // const response = await postBroker(token, "user-exchange/add", body);
      const response = await postClientExchange(token, 'client-exchange/', body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const editClientExchange = createAsyncThunk(
  'clientExchange/editClientExchange',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      // const response = await putBroker(token, `user-exchange/update/${body.exchangeId}`, body);
      const response = await putClientExchange(token, `client-exchange/${body.id}`, body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const clientExchangeSlice = createSlice({
  name: 'getClientExchange',
  initialState,
  reducers: {
    setClientExchange: (state, action: PayloadAction<ClientExchange>) => {
      state.getClientExchangeData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientExchangeForIndivisual.pending, (state, action) => {
      state.getLoading = false
      state.getClientExchangeData = null
    })
    builder.addCase(getClientExchangeForIndivisual.fulfilled, (state, action) => {
      state.getLoading = true
      state.getClientExchangeData = action.payload
      state.getErrors = null
    })
    builder.addCase(getClientExchangeForIndivisual.rejected, (state, action) => {
      state.getLoading = true
      state.getClientExchangeData = null
      state.getErrors = action.payload
    })
    builder.addCase(createClientExchange.pending, (state, action) => {
      state.createLoading = false
      state.createClientExchangeData = null
    })
    builder.addCase(createClientExchange.fulfilled, (state, action) => {
      state.createLoading = true
      state.createClientExchangeData = action.payload
      state.createErrors = null
    })
    builder.addCase(createClientExchange.rejected, (state, action) => {
      state.createLoading = true
      state.createErrors = action.payload
      state.createClientExchangeData = null
    })
    builder.addCase(editClientExchange.pending, (state, action) => {
      state.editLoading = false
      state.editClientExchangeData = null
    })
    builder.addCase(editClientExchange.fulfilled, (state, action) => {
      state.editLoading = true
      state.editClientExchangeData = action.payload
      state.editErrors = null
    })
    builder.addCase(editClientExchange.rejected, (state, action) => {
      state.editLoading = true
      state.editErrors = action.payload
      state.editClientExchangeData = null
    })
    builder.addCase(getClientExchangeForAlgoTrans.pending, (state, action) => {
      state.getAllLoading = true
      state.getAllClientExchangeData = null
    })
    builder.addCase(getClientExchangeForAlgoTrans.fulfilled, (state, action) => {
      state.getAllLoading = false
      state.getAllClientExchangeData = action.payload
      state.getErrors = null
    })
    builder.addCase(getClientExchangeForAlgoTrans.rejected, (state, action) => {
      state.getAllLoading = false
      state.getAllClientExchangeData = null
      state.getAllErrors = action.payload
    })
  },
})

export default clientExchangeSlice.reducer
export const {setClientExchange} = clientExchangeSlice.actions
