/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import { Link, useLocation } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, Button, MenuItem, InputLabel, Checkbox, FormControlLabel, IconButton, InputAdornment, Autocomplete, Box, Divider } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import 'react-datepicker/dist/react-datepicker.css'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { createUser } from '../../http'
import { PhoneVerificationDialog } from './PhoneVerificationDialog'
import dayjs from 'dayjs';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { countryCode as countryCodeList } from '../../helpers';
import bcrypt from 'bcryptjs';
import {useAuth} from '../core/Auth'
import {login, googleSignIn} from '../core/_requests'
import { googleLogout, useGoogleLogin, GoogleLogin } from '@react-oauth/google'


const initialValues = {
  userName: '',
  firstName: '',
  lastName: '',
  middleName: '',
  dateOfBirth: '',
  gender: '',
  email: '',
  phoneNumber: '',
  status: 'Active',
  password: '',
  currency: '',
  imageLink: '',
  emailVerified: false,
  phoneVerified: false,
  testAccount: false,
  occupation: '',
  incomeSource: '',
  socialMediaLink: '',
  nationality: '',
  emailVerificationRequested: false,
  phoneVerificationRequested: false,
  language: 'English',
  changepassword: '',
  referralCode: '',
  acceptTerms: false,
  country: '',
  countryCode: '',
  isdCode: '',
  countryObject: { countryCode: 'IN', label: 'India', phone: '+91' }

}

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+~\-={}\[\]|\\:;"'<>,.?/])[A-Za-z\d!@#$%^&*()_+~\-={}\[\]|\\:;"'<>,.?/]{8,}$/;
// const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;

const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('First name is required')
    .min(3, 'First name should be minimum 3 characters')
    .max(50, 'First name should be maximum 50 characters'),
  lastName: Yup.string()
    .trim()
    .required('Last name is required')
    .min(3, 'Last name should be minimum 3 characters')
    .max(50, 'Last name should be maximum 50 characters'),
  dateOfBirth: Yup.date()
    .typeError('Date of birth is required')
    .required('Date of birth is required')
    .max(new Date(Date.now() - 315360000000), 'You must be at least 10 years')
    .nullable(),
  // .max(new Date(Date.now() - 567648000000), 'You must be at least 18 years'),
  countryObject: Yup.object().shape({
    countryCode: Yup.string().nullable().required('Country code is required'),
    label: Yup.string().nullable().required(),
    phone: Yup.string().nullable().required(),
  }),
  phoneNumber: Yup.string()
    .trim()
    .required('Phone number is required')
    .matches(phoneRegExp, 'Phone number is not valid'),
  gender: Yup.string()
    .required('Gender is required').nullable(),
  email: Yup.string()
    .trim()
    .required('Email is required')
    .email('Wrong email format')
    .min(3, 'Email should be minimum 3 characters')
    .max(50, 'Email should be maximum 50 characters'),

  password: Yup.string()
    .trim()
    .required('Password is required')
    .min(8, 'Password should be at least 8 characters.')
    .max(50, 'Password should be maximum 50 characters')
    .matches(passwordRegex, 'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'),
  changepassword: Yup.string()
    .trim()
    .required('confirm Password is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  referralCode: Yup.string()
    .trim()
    .max(6, 'Referral Code should be maximum 6 characters'),
  acceptTerms: Yup.bool()
    .oneOf([true], 'You must accept the terms and conditions'),
})

export function Registration() {
  const {saveAuth, setCurrentUser} = useAuth()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  const salt = bcrypt.genSaltSync(10);
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [userId, setUserId] = useState(0)
  const [phoneNo, setPhoneNo] = useState('') 
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [referralCode, setReferralCode] = useState<string>(searchParams.get('ref-code') || '');
  // const { saveAuth, setCurrentUser } = useAuth()

  const signInUrl = referralCode ? `/auth/sign-in?ref-code=${referralCode}` : '/auth/sign-in';
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  const handleMouseDownConfirmPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClose = (value) => {
    setIsOpen(false)
  }

  initialValues.countryCode = 'IN';
  initialValues.dateOfBirth = new Date().toDateString();
  initialValues.referralCode = referralCode;

  const validateOption: (option: { countryCode: string; label: string; phone: string; }, value: { countryCode: string; label: string; phone: string; }) => boolean = (option, value) => {
    // your validation logic goes here
    return true; // return true if the option is valid, false otherwise
  };

  const googleLogin =  useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        // const encodedAuth_Code = Buffer.from(codeResponse?.code).toString('base64');
        const encodedAuth_Code = btoa(codeResponse?.code);
        const {data: auth} = await googleSignIn(encodedAuth_Code, referralCode)
        if (auth?.apierror?.errorCode === 2031) {          
          saveAuth(undefined)       
          setLoading(false)
        } 
        else if (auth?.apierror?.errorCode === 2032 || auth?.apierror?.errorCode === 2033){
          saveAuth(undefined)        
        }
        else if (auth?.apierror?.errorCode === 2034){
          saveAuth(undefined)         
        }
        else {          
          saveAuth(auth)          
          const user = auth
          setCurrentUser(user)                    
        }
      }
      catch(error: any){
        saveAuth(undefined)
      }
    },
    onError: (error) => console.log('Login Failed:', error),
    flow: 'auth-code'
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      try {
        setLoading(true)
        const hashedPassword  = bcrypt.hashSync(values.password, salt);
        const body: any = {
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          dateOfBirth: values.dateOfBirth,
          gender: values.gender,
          email: values.email.trim(),
          country: values.countryObject.label,
          countryCode: values.countryObject.countryCode,
          isdCode: values.countryObject.phone,
          phoneNumber: values.phoneNumber.trim(),
          password: values.password,
          referralCode: values.referralCode
        }

        try {
          const response = await createUser('auth/signup', body)

          if (response.status === 200) {
            if (response.data.code === '400') {
              setStatus(response.data.notifications[0].notificationDescription)
            } else {
              setSuccess('You are registered successfully! Please login.')
              setUserId(response.data?.modal?.data[0]?.id)
              setPhoneNo(`${values.countryObject.phone}${values.phoneNumber.trim()}`)
              formik.resetForm();
              setIsOpen(true);
            }
          } else {
            setStatus('The registration details is incorrect')
          }
        }
        catch (error: any) {
          setStatus(error?.response?.data?.apierror?.message)
        }

        setLoading(false)

      } catch (error: any) {
        // saveAuth(undefined)
        setStatus(error?.response?.data?.apierror?.message);
        setSubmitting(false);
        setLoading(false);
      }
    },
  })

  const getDateValue = () => {
    if (formik.values.dateOfBirth) {
      return new Date(formik.values.dateOfBirth);
    } else {
      return new Date();
    }
  };

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='mb-5 text-center'>
          <h1 className='text-dark mb-3'>Create an Account</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            Already have an account?
            <Link to={signInUrl} className='link-primary fw-bolder' style={{ marginLeft: '5px' }}>
              SignIn
            </Link>
          </div>
        </div>

        <div className='d-flex align-items-center mb-5'>
          <div className='border-bottom border-gray-300 mw-50 w-100'></div>
          <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        </div>

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        {success && (
          <div className='mb-lg-15 alert alert-success'>
            <div className='alert-text font-weight-bold'>{success}</div>
          </div>
        )}

        <div className='row fv-row mb-2'>
          <div className='col-xl-6 col-xs-6  mb-1'>
            <div className='position-relative mb-1'>
              <TextField
                required
                size='medium'
                label='First name'                
                fullWidth
                className='form-control form-control-lg form-control-solid'
                inputProps={{
                  maxLength: 50
                }}
                // sx={{ minWidth: 200 }}           
                {...formik.getFieldProps('firstName')} />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='fs-8'>{formik.errors.firstName}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-xl-6 col-xs-6  mb-1'>
            <TextField
              required
              size='medium'
              label='Last name'
              fullWidth
              className='form-control form-control-lg form-control-solid'
              inputProps={{
                maxLength: 50
              }}
              {...formik.getFieldProps('lastName')} />

            {formik.touched.lastName && formik.errors.lastName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' className='fs-8'>{formik.errors.lastName}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row fv-row mb-2'>
          <div className='col-xl-6 col-xs-6 mb-1'>
            <div className='position-relative mb-1'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date of Birth"
                  value={dayjs(new Date(formik.values.dateOfBirth))}
                  format="DD/MM/YYYY"
                  maxDate={dayjs(new Date())}
                  className="form-control form-control-lg form-control-solid"
                  sx={{ '& .MuiInputBase-root': { minWidth: 210 } }}
                  onChange={(value) => formik.setFieldValue('dateOfBirth', value)}
                />
              </LocalizationProvider>
              {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='fs-8'>{formik.errors.dateOfBirth}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-xl-6 col-xs-6 mb-1'>
            <div className='row'>
              <div className='col-xl-5'>
                <div className='position-relative mb-1'>
                  <FormControl required fullWidth size="medium" className='form-control form-control-lg form-control-solid'>
                    <Autocomplete
                      disablePortal
                      id="Exchange"
                      options={countryCodeList}
                      getOptionLabel={(option) => option.phone}
                      size='medium'
                      clearIcon={false}
                      isOptionEqualToValue={(option, value) => { return ( 
                        option.countryCode === value.countryCode &&
                        option.label === value.label &&
                        option.phone === value.phone
                      )}}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 1, flexShrink: 0, fontSize: 6 } }} {...props}>
                          <img
                            loading="lazy"
                            width="15"
                            src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 1x`}
                            alt=""
                          />
                          <span className='fs-9'> {option.phone}-{option.label}</span>
                        </Box>

                      )}
                      value={formik.values.countryObject}
                      // isOptionEqualToValue= {validateOption(formik.values.countryObject, formik.values.countryObject)}
                      onChange={(e, value) => { formik.setFieldValue('countryObject', value) }}
                      renderInput={(params) => (<TextField {...params} label="Code" placeholder='select...' />)}
                    />
                  </FormControl>
                  {formik.touched.countryCode && formik.errors.countryCode && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert' className='fs-8'>{formik.errors.countryCode}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-xl-7'>
                <TextField
                  required
                  size='medium'
                  label='Phone No.'
                  className='form-control form-control-lg form-control-solid'
                  fullWidth
                  inputProps={{
                    maxLength: 10
                  }}
                  {...formik.getFieldProps('phoneNumber')}
                />

                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert' className='fs-8'>{formik.errors.phoneNumber}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='row fv-row mb-2'>
          <div className='col-xl-6 col-xs-6'>
            <div className='position-relative mb-1'>
              <FormControl required fullWidth size="medium" className='form-control form-control-lg form-control-solid'>
                <InputLabel id="demo-select-small">Gender</InputLabel>
                <Select
                  required
                  label='Gender'
                  value={formik.values.gender}
                  onChange={(event: SelectChangeEvent) => formik.setFieldValue('gender', event.target.value)}
                >
                  <MenuItem value="">Select a Gender...</MenuItem>
                  <MenuItem value='Male'>Male</MenuItem>
                  <MenuItem value='Female'>Female</MenuItem>
                  <MenuItem value='Other'>Other</MenuItem>
                </Select>
              </FormControl>
              {formik.touched.gender && formik.errors.gender && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='fs-8'>{formik.errors.gender}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='col-xl-6 col-xs-6'>
            <TextField
              required
              size='medium'
              label='Email'
              className='form-control form-control-lg form-control-solid'
              fullWidth
              inputProps={{
                maxLength: 50
              }}
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' className='fs-8'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row fv-row mb-2' data-kt-password-meter='true'>
          <div className='col-xl-6 col-xs-6'>
            <div className='position-relative mb-1'>
              <TextField
                required
                size='medium'
                label='Password'
                className='form-control form-control-lg form-control-solid'
                type={showPassword ? 'text' : 'password'}
                fullWidth
                autoComplete='off'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                {...formik.getFieldProps('password')}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='fs-8'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            <div
              className='d-flex align-items-center mb-1 mt-2'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
          </div>
          <div className='col-xl-6 col-xs-6'>
            <TextField
              required
              size='medium'
              type={showConfirmPassword ? 'text' : 'password'}
              label='Confirm Password'
              className='form-control form-control-lg form-control-solid'
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              {...formik.getFieldProps('changepassword')}
            />

            {formik.touched.changepassword && formik.errors.changepassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' className='fs-8'>{formik.errors.changepassword}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row fv-row mb-2'>
          <div className='col-xl-12 col-xs-12'>
            <TextField
              size='medium'
              label='Referral Code'
              className='form-control form-control-lg form-control-solid'
              fullWidth
              inputProps={{
                maxLength: 6
              }}
              {...formik.getFieldProps('referralCode')}
            />
            {formik.touched.referralCode && formik.errors.referralCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert' className='fs-8'>{formik.errors.referralCode}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row fv-row'>
          <div className='col-xl-12 col-xs-12 ms-2'>
            <div className='form-check form-check-custom form-check-solid'>
              <FormControlLabel control={
                <Checkbox
                  className='form-check-input'
                  {...formik.getFieldProps('acceptTerms')}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
                label={
                  <p className='form-check-label fw-bold text-gray-700 fs-6 mt-3'>
                    I Agree the{' '}
                    <Link to='/auth/terms' className='ms-1 link-primary'>
                      terms and conditions
                    </Link>
                  </p>
                } />
            </div>
          </div>
        </div>
        <div className='fv-row mb-2'>
          <div className='col-xl-12 col-xs-12'>
            <div className='form-check form-check-custom form-check-solid'>
              {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert' className='fs-8'>{formik.errors.acceptTerms}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-xl-6 col-xs-6'>
            <Button
              type='submit'
              id='kt_sign_up_submit'
              variant='contained'
              color='info'
              fullWidth
              size='large'
              className='mb-2'
              // className='btn btn-lg btn-primary w-100 mb-5'
              // disabled={formik.isSubmitting || formik.isValid || !formik.values.acceptTerms}
              disabled={loading}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </Button>            
          </div>
          <div className='col-xl-6 col-xs-6'>            
            <Link to={signInUrl}>
              <Button
                // id='kt_login_signup_form_cancel_button'
                color='inherit'
                variant='contained'
                fullWidth
                size='large'
              >
                Cancel
              </Button>           
            </Link>
          </div>
        </div>
        <div className='text-center text-muted text-uppercase fw-bolder mb-2'>or</div>
        <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-2' onClick={() => googleLogin()}>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-20px me-3'
            />
            Continue with Google
          </a>
        {/* end::Form group */}
      </form>
      {isOpen && <PhoneVerificationDialog open={isOpen} id={userId} phoneNo={phoneNo} onClose={handleClose} signIn={false} />}
    </>
  )
}
