export const CurrencyFormater = (value,currency, append) => {
  if (value) {
    // return `${append ? '₹' : ''} ${value
    return `${append ? (currency === 'INR' ? '₹' : '$') : ''} ${value
      .toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')}`
  }

  return 0
}
