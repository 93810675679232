import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {SubscriptionPlan} from '../interfaces/SubscriptionPlan'
import {
  createSubscriptionPlan as postSubscriptionPlan,
  editSubscriptionPlan as putSubscriptionPlan,
  getAllSubscriptionPlan,
} from '../http'

interface SubscriptionPlanState {
  getSubscriptionPlanData: SubscriptionPlan | null
  getLoading: boolean
  getErrors: any
  createSubscriptionPlanData: SubscriptionPlan | null
  createLoading: boolean
  createErrors: any
  editSubscriptionPlanData: SubscriptionPlan | null
  editLoading: boolean
  editErrors: any
}

const initialState: SubscriptionPlanState = {
  getSubscriptionPlanData: null,
  getLoading: false,
  getErrors: null,
  createSubscriptionPlanData: null,
  createLoading: false,
  createErrors: null,
  editSubscriptionPlanData: null,
  editLoading: false,
  editErrors: null,
}

export const getSubscriptionPlanAll = createAsyncThunk(
  'subscriptionPlan/getSubscriptionPlanAll',
  async ({token}: {token: any;}, thunkAPI) => {
    try {
      const response = await getAllSubscriptionPlan(token, 'subscription-plan')
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const createSubscriptionPlan = createAsyncThunk(
  'subscriptionPlan/createSubscriptionPlan',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      const response = await postSubscriptionPlan(token, 'subscription-plan/', body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const editSubscriptionPlan = createAsyncThunk(
  'subscriptionPlan/editSubscriptionPlan',
  async ({data}: {data: any}, thunkAPI) => {
    try {
      const {token, body} = data
      // const response = await putBroker(token, `user-exchange/update/${body.exchangeId}`, body);
      const response = await putSubscriptionPlan(token, `subscription-plan/${body.id}`, body)
      return response.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

// reducers -> reduce to a specific state -> changes state

export const subscriptionPlanSlice = createSlice({
  name: 'getSubscriptionPlan',
  initialState,
  reducers: {
    setSubscriptionPlan: (state, action: PayloadAction<SubscriptionPlan>) => {
      state.getSubscriptionPlanData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionPlanAll.pending, (state, action) => {
      state.getLoading = false
      state.getSubscriptionPlanData = null
    })
    builder.addCase(getSubscriptionPlanAll.fulfilled, (state, action) => {
      state.getLoading = true
      state.getSubscriptionPlanData = action.payload
      state.getErrors = null
    })
    builder.addCase(getSubscriptionPlanAll.rejected, (state, action) => {
      state.getLoading = true
      state.getSubscriptionPlanData = null
      state.getErrors = action.payload
    })
    builder.addCase(createSubscriptionPlan.pending, (state, action) => {
      state.createLoading = false
      state.createSubscriptionPlanData = null
    })
    builder.addCase(createSubscriptionPlan.fulfilled, (state, action) => {
      state.createLoading = true
      state.createSubscriptionPlanData = action.payload
      state.createErrors = null
    })
    builder.addCase(createSubscriptionPlan.rejected, (state, action) => {
      state.createLoading = true
      state.createErrors = action.payload
      state.createSubscriptionPlanData = null
    })
    builder.addCase(editSubscriptionPlan.pending, (state, action) => {
      state.editLoading = false
      state.editSubscriptionPlanData = null
    })
    builder.addCase(editSubscriptionPlan.fulfilled, (state, action) => {
      state.editLoading = true
      state.editSubscriptionPlanData = action.payload
      state.editErrors = null
    })
    builder.addCase(editSubscriptionPlan.rejected, (state, action) => {
      state.editLoading = true
      state.editErrors = action.payload
      state.editSubscriptionPlanData = null
    })
  },
})

export default subscriptionPlanSlice.reducer
export const {setSubscriptionPlan} = subscriptionPlanSlice.actions

