import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getUserActivityData } from '../http'
import { UserActivity } from '../interfaces/UserActivity'
import { actions } from 'react-table'

interface UserActivityState {
    userActivityData: UserActivity | null,
    loading: boolean,
    errors: any
}

const initialState: UserActivityState = {
    userActivityData: null,
    loading: false,
    errors: null
}

export const getUserActivity = createAsyncThunk(
    'userActivity/getUserActivity',
    async ({ token }: { token: any }, thunkAPI) => {
        try {
            const response = await getUserActivityData(token, 'user-activity/sorted')
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const userActivitySlice = createSlice({
    name: 'userActivity',
    initialState,
    reducers: {
        setUserActivity: (state, action: PayloadAction<UserActivity>) => {
            state.userActivityData = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserActivity.pending, (state, action) => {
            state.loading = true
            state.userActivityData = null
        })
        builder.addCase(getUserActivity.fulfilled, (state, action) => {
            state.loading = false
            state.userActivityData = action.payload
            state.errors = null
        })
        builder.addCase(getUserActivity.rejected, (state, action) => {
            state.loading = false
            state.errors = action.payload
            state.userActivityData = null
        })
    }
})

export default userActivitySlice.reducer
export const { setUserActivity } = userActivitySlice.actions